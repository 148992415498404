import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { GoogleEvent } from '../models/GoogleEvent';
import { environment } from './../../environments/environment';
import { MailerService } from './mailer.service';
import { UIMessagingService } from './uimessaging.service';

declare const gapi: any;

const CLIENT_ID = '73711149129-rs6phppfpgbobt9fkvo3669ljeuf3l2f.apps.googleusercontent.com';
const API_KEY = environment.config.apiKey;
// Array of API discovery doc URLs for APIs used by the quickstart
const DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'];
// Authorization scopes required by the API; multiple scopes can be
// included, separated by spaces.
const SCOPES = 'https://www.googleapis.com/auth/calendar.events';

export interface HealthCareObject {
  files: any[];
  dataset: string;
  go: number;
  datastore: string;
  stackID: string;
}

@Injectable({ providedIn: 'root' })
export class GapiOperationsService {
  private _healthcare: any;
  gcloud_projectname: any;
  gcloud_projectlocation: any;
  healthcare: any;
  isSignedIn: boolean;

  constructor(
    private http: HttpClient,
    private uiMessaging_$: UIMessagingService,
    private mailer_$: MailerService,
  ) {
    this.gcloud_projectname = environment.config.gapi.projectname;
    this.gcloud_projectlocation = environment.config.gapi.location;
  }

  getGapi() {
    return gapi;
  }

  handleClientLoad() {
    // Load the API client and auth2 library.
    const loadGapiClient = new Promise(function (resolve, reject) {
      gapi.load('client:auth2', resolve);
    });
    return Promise.all([loadGapiClient]);
  }

  /**
   * Called when the signed in status changes, to update the UI
   * appropriately. After a sign-in, the API is called.
   * */
  updateSigninStatus(isSignedIn) {
    if (isSignedIn) {
      console.log('isSignedIn: ', isSignedIn);
      this.isSignedIn = true;
    } else {
      console.log('isSignedIn: ', isSignedIn);
      this.isSignedIn = false;
    }
  }

  /**
   * Print the summary and start datetime/date of the next ten events in
   * the authorized user's calendar. If no events are found an
   * appropriate message is printed.
   */
  createGoogleCalendarEvent(googleEventObject: GoogleEvent) {
    console.log('Event', googleEventObject);
    return new Promise((resolve, reject) => {
      gapi.client.calendar.events
        .insert({
          calendarId: 'primary',
          resource: googleEventObject,
        })
        .then(
          function (response) {
            const event = response.result;
            resolve(event);
            // this.gotoUrl(event.htmlLink);
          },
          error => {
            reject(error);
          },
        );
    });
  }

  gotoUrl(url: string, target?: string) {
    window.open(url, target || '_blank');
  }

  /**
   * Initializes the API client library and sets up sign-in state
   * listeners.
   * */
  async initClient() {
    const updateSigninStatus = this.updateSigninStatus;
    const gapiClientInit = await gapi.client
      .init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES,
      })
      .then(
        () => true,
        error => {
          console.log('init error', error);
          return error;
        },
      );

    return gapiClientInit.error || { code: 200 };
  }

  setGapiAuth2IsSignedInListener(func) {
    gapi.auth2.getAuthInstance().isSignedIn.listen(func);
  }

  getApiAuthInstance() {
    // Listen for sign-in state changes.
    gapi.auth2.getAuthInstance().isSignedIn.listen(this.updateSigninStatus);
    // Handle the initial sign-in state.
    this.updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get());
  }

  /**
   * Append a pre element to the body containing the given message
   * as its text node. Used to display the results of the API call.
   *
   * @param message Text to be placed in pre element.
   * */
  appendPre(message) {
    const pre = document.getElementById('content');
    const textContent = document.createTextNode(message + '\n');
    pre.appendChild(textContent);
  }

  async checkIfDataSetExists(datasetname) {
    const projectId = environment.config.gapi.projectname;
    const cloudRegion = environment.config.gapi.location;
    const url = `${environment.config.healthcareApi}/projects/${projectId}/locations/${cloudRegion}/datasets/${datasetname}`;
    return this.http
      .get(url)
      .toPromise()
      .then(
        data => (data['name'] ? true : false),
        reason => {
          console.log(`Some error during dataset existence check: ${datasetname}`, reason);
          return false;
        },
      )
      .catch(err => {
        console.error(err);
      });
  }

  async createDataSet(dataSetName: string) {
    console.log('---------------------------------');
    console.log('createDataSet');
    console.log('---------------------------------');

    const datasetId = dataSetName.replace(/\s/g, '');

    if (await this.checkIfDataSetExists(datasetId)) console.log('The dataset exists');
    else {
      const projectId = environment.config.gapi.projectname;
      const cloudRegion = environment.config.gapi.location;
      const url = `${environment.config.healthcareApi}/projects/${projectId}/locations/${cloudRegion}/datasets/${datasetId}/create`;

      return this.http
        .get(url)
        .toPromise()
        .then(
          response => (response['done'] ? 200 : 0),
          reason => {
            console.log('reason: ', reason);
            return 0;
          },
        );
    }
  }

  // TODO: Improve the STUDY delete with
  // https://cloud.google.com/healthcare/docs/reference/rest/v1beta1/projects.locations.datasets.dicomStores.studies/delete.
  deleteStudy() {
    console.log('Work needed here.');
  }

  async createDataStore(dicomStoreId: string, datasetId: string, projectId, cloudRegion): Promise<number> {
    const storename = dicomStoreId.replace(/\s/g, '');
    return this.http
      .get(this.getCreateDataStoreUrl(projectId, cloudRegion, datasetId.replace(/\s/g, ''), storename))
      .toPromise()
      .then(result => {
        console.log(`Store ${storename} created`, result);
        return 200;
      })
      .catch(reason => {
        console.log(`Store ${storename} not created`, reason);
        return 0;
      });
  }

  downloadDICOMFiles({ viewerurl, fileId, fileDesc }, creator: string) {
    const url = this.generateDownloadDICOMDataURL(viewerurl);
    return this.http.get(url, { params: { fileId, creator, fileDesc } });
  }

  // FIXME: This cloud function takes so long to finish, ar at least to answer.... this is impofrtant to be fixed ASAP.
  // NOTE: handle them by chunks to avoid one request per list item.
  async deleteStoresByCaseName(datasetId, dataStoreList) {
    datasetId = datasetId.replace(/\s/g, '');
    // FIXME: This cloud function takes so long to finish, ar at least to answer.... this is important to be fixed ASAP.
    if (!dataStoreList && !dataStoreList.length) {
      return;
    }
    const projectId = environment.config.gapi.projectname;
    const cloudRegion = environment.config.gapi.location;

    const promisesStack = [];
    dataStoreList.forEach(dataStoreName => {
      dataStoreName = dataStoreName.replace(/\s/g, '');
      const url = `${environment.config.healthcareApi}/projects/${projectId}/locations/${cloudRegion}/datasets/${datasetId}/dicomStores/${dataStoreName}/delete`;
      promisesStack.push(this.http.get(url).toPromise());
    });
    return Promise.all(promisesStack);
  }

  async healthcareCreate({
    datasetname,
    datastorename,
    stackID,
    files,
    date,
    storecomplementname,
  }): Promise<HealthCareObject> {
    // Try to create the DataSet.
    await this.createDataSet(datasetname);

    // const setNameExists = await this.createDataSet(datasetname);
    // if (setNameExists !== 200) await this.createDataSet(datasetname);

    return {
      files: files,
      dataset: datasetname,
      go: 200,
      datastore: datastorename,
      stackID: stackID,
    };
  }

  private async getOperationStatus(operationName, projectId, location, datasetId, datastoreId) {
    console.log('getOperationStatus :');
    const url = `${environment.constants.cloudfunctionsURL}healthcareApi/projects/${projectId}/locations/${location}/datasets/${datasetId}/operations`;
    const result = await this.http
      .post(url, { operationName, datastoreId })
      .toPromise()
      .catch(reason => {
        console.log('reason: ', reason);
        return reason;
      });
    console.log('result: ', result);
    return result;
  }

  handleSendResults(operationName, datasetId, datastoreId) {
    setTimeout(async () => {
      const data = await this.getOperationStatus(
        operationName,
        environment.config.projectId,
        environment.config.gapi.location,
        datasetId,
        datastoreId,
      );

      // console.log('==============================');
      // console.log('data: ', data);
      // console.log('==============================');

      let messageData;

      if (data['error'] === undefined) {
        messageData = 'Message: ' + data['message'] + '\n';
        messageData += 'Operation:' + operationName + '\n';
        messageData += 'Dataset:' + datasetId + '\n';
        messageData += 'Datastore:' + datastoreId + '\n';
      } else {
        messageData = 'Encountered errors. Sample error:\n';
        messageData += 'Resource on which error occured:' + JSON.stringify(data['error']['errors'] + '\n');
      }

      this.mailer_$.sendOperationStatusEmail(messageData, 'Import DICOM Files', 'Succeeded', 'arengifo@nuagedx.com');
    }, 15000);
  }

  async importDICOMFiles(datasetId, dicomStoreId) {
    const gsuri = this.createGSURI(datasetId, dicomStoreId);

    const handleResult = result => {
      this.uiMessaging_$.toastMessage(`Import ${gsuri} succeeded`, 'INFO');
      console.log('...');
      console.log('Import DICOM Files result', result);
      console.log(result.data.name);
      this.handleSendResults(result.data.name, datasetId, dicomStoreId);

      console.log('...');
      return result;
    };

    const handleError = reason => {
      console.log('...');
      console.log('Error importing DICOM Files', reason);
      console.log('...');
      this.uiMessaging_$.toastMessage(`Error importing ${gsuri}: ${reason}`, 'INFO');
      return reason;
    };

    return this.http
      .post(this.createImportDICOMDataUrl(datasetId, dicomStoreId), { gsuri })
      .toPromise()
      .then(result => handleResult(result))
      .catch(reason => handleError(reason));
  }

  private getCreateDataStoreUrl(projectId, cloudRegion, datasetname, storename) {
    return `${environment.config.healthcareApi}/projects/${projectId}/locations/${cloudRegion}/datasets/${datasetname}/dicomStores/${storename}/create`;
  }

  private createImportDICOMDataUrl(datasetId, dicomStoreId) {
    const projectId = environment.config.gapi.projectname;
    const cloudRegion = environment.config.gapi.location;
    return `${environment.config.healthcareApi}/projects/${projectId}/locations/${cloudRegion}/datasets/${datasetId}/dicomStores/${dicomStoreId}`;
  }

  private createGSURI(datasetId, dicomStoreId) {
    const baseAppUrl = environment.config.storageBucket;
    return encodeURI(`gs://${baseAppUrl}/dcmstore/${datasetId}/${dicomStoreId}/**`);
  }

  private generateDownloadDICOMDataURL(viewerurl: string) {
    const [, , projectId, , location, , datasetId, , dicomStoreId, , studyId] = viewerurl.split('/');
    return `${environment.config.healthcareApiV5}/projects/${projectId}/locations/${location}/datasets/${datasetId}/dicomStores/${dicomStoreId}/study/${studyId}/download`;
  }
}
