<h2 mat-dialog-title>
  Select Patients
</h2>
<mat-dialog-content>
  <mat-selection-list #patientsList>
    <mat-list-option *ngFor="let patient of data.patients"
                     [value]="patient">
      <strong>
        Name:
      </strong>
      {{ patient.name }}
      <br>
      <strong>
        Age:
      </strong>
      {{ patient.age }}
      <br>
      <strong>
        Last Visit:
      </strong>
      {{ patient.lastVisit | date:'mediumDate' }}
      <br>
    </mat-list-option>
  </mat-selection-list>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button
          (click)="onCancel()">
    Cancel
  </button>
  <button mat-button
          (click)="onSelect(patientsList.selectedOptions.selected)">
    Select
  </button>
</mat-dialog-actions>
