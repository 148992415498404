import 'firebase/firestore';

import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import firebase from 'firebase/app';
import { from } from 'rxjs';
import { ClioService } from 'src/app/services/clio.service';
import { WindowsManagerService } from 'src/app/services/windows-manager.service';
import { environment } from 'src/environments/environment';
import { Labels } from '../../../dictionaries/labels';

import { DialogService } from 'src/app/dialog.service';
import { RedirectionService } from 'src/app/services/redirection.service';
import { AuthService } from '../../../services/auth.service';
import { UIMessagingService } from '../../../services/uimessaging.service';
import { ClientProfileComponent } from '../../clientprofile/clientprofile.component';
import { SessionStorageService } from './../../../services/session-storage.service';

const ADD_TO_CLIO = 'addtoclio';
const ADD_TO_CLIO_EU = 'addtoclio_eu';
const API_AUTH_CALLBACK = 'apiauthcallback';
const API_AUTH_CALLBACK_EU = 'apiauthcallback_eu';
const API_AUTH_CALLBACK_CLIO = 'apiauthcallback_clio';
const OPEN_APP = 'openapp';
const OPEN_APP_EU = 'openapp_eu';
const CLIENT = 'client';
const CLIENT_EU = 'client_eu';
const SSO_CALLBACK = environment.config.clio.SSO.usCallbackStr;
const SSO_CALLBACK_EU = environment.config.clio.SSO.euCallbackStr;
const CLIO_AUTH = 'clioauth';
const DEFAULT_PLAN = 'fp';

@Component({
  templateUrl: './ClioAuth.component.html',
  styleUrls: ['./ClioAuth.component.scss'],
})
export class ClioAuthComponent implements OnInit {
  profileDialogRef: any;
  v: any;
  error = false;
  Labels = Labels;
  errorMessage: string;
  SSOCompleted = false;
  sso: string;

  constructor(
    private windowsManager_$: WindowsManagerService,
    private sessionStorage_$: SessionStorageService,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    private clio_$: ClioService,
    public auth_$: AuthService,
    private dialog_$: DialogService,
    public ref: ChangeDetectorRef,
    private router: Router,
    private redirection_$: RedirectionService,
    private uiMessaging_$: UIMessagingService,
  ) {}

  async ngOnInit() {
    if (this.activatedRoute.snapshot.params['origin'] === ADD_TO_CLIO) {
      this.sessionStorage_$.setAddToClioEU(false);
      this.sessionStorage_$.setAddToClioStarted(true);
    }

    if (this.activatedRoute.snapshot.params['origin'] === ADD_TO_CLIO_EU) {
      this.sessionStorage_$.setAddToClioEU(true);
      this.sessionStorage_$.setAddToClioStarted(true);
    }

    // Check if the user is already logged in
    if (this.auth_$.isSignedIn()) {
      this.signedInActions();
    } else {
      this.notSignedInActions();
    }
  }

  authUserSubscription() {
    this.auth_$.user.subscribe({
      next: async v => {
        if (v) {
          console.log('v: ', v);
          this.handleExistingUser(v);
        } else {
          console.log('No user');
        }
      },
      error: e => console.log('error', e),
      complete: () => console.log('=== end ==='),
    });
  }

  reloadThisPage(event) {
    event.preventDefault();
    window.location.href = window.location.href;
  }

  createClioWebHooks() {
    const userdocid = this.auth_$.userData.value['id'];
    const url = `${environment.constants.cloudfunctionsURL}clio-createWebHooksV3`;

    // FIXME: Have to validate existing webhooks before create new ones.
    this.http.post(url, { userdocid }).subscribe({
      next: async answer => {
        console.log('createClioWebHooks', answer);
      },
      error: err => {
        if (environment.config.raygun) {
          const rg4js = require('raygun4js');
          rg4js('withCustomData', { userdocid, err, message: 'There was a problem creating Clio WebHooks.' });
        }
      },
    });
  }

  getAuthorizationCode2(observer, code, nonce, sso?: number) {
    this.auth_$.showLoader('Handling SSO Sign In...');
    const eu = this.isClioEU();
    const redirect_uri = eu ? environment.config.clio.SSO.callback_eu : environment.config.clio.SSO.callback;
    const options = { code, redirect_uri, nonce, sso, eu };

    from(firebase.functions().httpsCallable('clio-getSSONewAuthorizationCodeV2')(options)).subscribe(observer);
    // from(firebase.functions().httpsCallable('clio-getSSONewAuthorizationCode')(options)).subscribe(observer);
  }

  getAuthorizationCode(observer, code, callback?: string) {
    const userdocid = this.auth_$.userData.value['id'];
    if (!userdocid) {
      console.log('No userdocid');
      return;
    }
    const eu = this.isClioEU();
    const { clientProfile_eu, clientProfile } = environment.config.clio.redirectsGroup;
    const redirect_uri = callback || eu ? clientProfile_eu : clientProfile;
    from(this.clio_$.clioGetAuthorizationCodeV3({ userdocid, code, redirect_uri, eu: eu ? 1 : 0 })).subscribe(observer);
  }

  getAuthorizationCodeV3(observer, code) {
    const userdocid = this.auth_$.userData.value['id'];
    if (!userdocid) {
      console.log('No userdocid');
      return;
    }
    const eu = this.isClioEU();
    const redirect_uri = `${environment.config.clio.redirect_uri}/client`;
    from(this.clio_$.clioGetAuthorizationCodeV3({ userdocid, code, redirect_uri, eu: eu ? 1 : 0 })).subscribe(observer);
  }

  getAuthorizationCodeV4(observer, code) {
    const userdocid = this.auth_$.userData.value['id'];
    if (!userdocid) {
      console.log('No userdocid');
      return;
    }
    // const redirect_uri = `${environment.config.clio.redirect_uri}/client`;
    const redirect_uri = `${environment.config.clio.redirect_uri}/${API_AUTH_CALLBACK}`;
    const eu = this.sessionStorage_$.getAddToClioEU() ? 1 : 0;
    from(this.clio_$.clioGetAuthorizationCodeV4({ userdocid, code, redirect_uri })).subscribe(observer);
  }

  handleObserverError() {
    this.error = true;
    this.errorMessage = this.v.error;
    this.ref.detectChanges();
  }

  handleExistingCode(code) {
    const observer = {
      next: async x => {
        await this.auth_$.userReady(this.auth_$.userData.value, 'handleExistingCode').catch(err => {
          console.error(err);
          return;
        });
        this.redirectToHome(true);
        this.createClioWebHooks();
        this.getUserLastSession();
        this.error = false;
        this.errorMessage = '';
      },
      error: err => {
        const message = 'There was an error, please try again.';
        console.error(message, err);
        const label = null;
        this.uiMessaging_$.toastMessage(message, label);
        this.error = true;
        this.errorMessage = 'There was an error, please try to reload this page.';
        this.ref.detectChanges();
      },
      complete: () => console.log('Observer completed'),
    };

    this.auth_$.userData.subscribe({
      next: async v => {
        console.log('>>>> v', v);
        if (Object.keys(v).length > 0) {
          this.getAuthorizationCode(observer, code);
        }
      },
      error: e => console.log('error', e),
      complete: () => console.log('=== end ==='),
    });
  }

  handleExistingUser(v) {
    this.v = v;
    this.handleRouteParamsSubscription();
    this.handleRouteMapSubscription();
  }

  handleRouteParamsSubscription() {
    this.v = this.activatedRoute.snapshot.queryParams;
    if (this.v.error) {
      this.handleObserverError();
    } else if (this.v.code) {
      this.handleExistingCode(this.v.code);
    }
  }

  handleRouteMapSubscription(eu = false) {
    this.activatedRoute.paramMap.subscribe(async params => {
      // Lets define when the user could be logged in or not.

      // Check if the user is logged in
      const { code, state } = this.activatedRoute.snapshot.queryParams;

      // NOTE: Is there a way to know this is EU or US?
      this.makeSignedOffActions(code, state);

      if (this.activatedRoute.snapshot.queryParams['error'] === 'access_denied') {
        console.log('access_denied');
        if (this.auth_$.userData.value['id']) {
          this.auth_$.logout('SSO cancelled');
          window.location.href = environment.config.host + `logout`;
        } else {
          const eu = this.sessionStorage_$.getAddToClioEU();
          const addtoclioStarted = this.sessionStorage_$.getAddToClioStarted() === 'true';
          // this.auth_$.logout(params['error_description']);
          this.auth_$.logout(params['error_description'], eu, addtoclioStarted);
        }
        this.redirectToHome(true);
      }
    });
  }

  makeSignedOffActions(code, nonce) {
    console.log('--- makeSignedOffActions ---');
    this.getAuthorizationCode2(
      {
        next: async answer => {
          const data = answer.data;
          console.log('data: ', data);
          // NOTE: Is there a way to know this is EU or US?
          this.signInWithCustomToken_local(data['customToken']).then(() => this.afterSignInWithToken());
        },
        error: (err: any) => console.log('error > >', err),
        complete: () => console.log('getAuthorizationCode2 observer completed'),
      },
      code,
      nonce,
    );
  }
  X;
  signInWithCustomToken_local(customToken) {
    console.log('--- signInWithCustomToken_local ---');
    return this.auth_$
      .signInWithCustomToken(customToken)
      .then(async userCredential => {
        // NOTE: Is there a way to know this is EU or US?
        console.log('signInWithCustomToken_local userCredential :', userCredential);
        this.afterSignInWithTokenV2(userCredential);
      })
      .catch(error => console.log('signInWithCustomToken_local error :', error));
  }

  makeSignedInActions(code) {
    const getCalendarObserver = () => {
      return {
        next: async x => await this.handleClientNext(x),
        error: err => this.handleClientError(err),
        complete: () => console.log('Observer completed'),
      };
    };
    const buildAuthUserObserver = (origin, code) => {
      console.log('origin :', origin);
      let obs;

      switch (origin) {
        case 'calendar':
          console.log('calendar');
          obs = getCalendarObserver();
          break;
        case CLIENT:
          console.log('CLIENT');
          obs = this.getClientObserver();
          break;
        case CLIENT_EU:
          console.log(CLIENT_EU);
          obs = this.getClientObserver();
          break;
        case ADD_TO_CLIO:
          console.log(ADD_TO_CLIO);
          this.sessionStorage_$.setAddToClioStarted(true);
          this.redirection_$.redirectToLoginClio();
          return;
        case ADD_TO_CLIO_EU:
          console.log(ADD_TO_CLIO_EU);
          this.sessionStorage_$.setAddToClioStarted(true);
          this.sessionStorage_$.setAddToClioEU(true);
          this.redirection_$.redirectToLoginClio();
          return;
        default:
          console.log('default: no obs here');
          break;
      }

      return {
        next: async v => {
          if (v !== null && Object.keys(v).length) {
            this.auth_$
              .userReady(v, 'makeSignedInActions')
              .then(async () => {
                // this.getAuthorizationCodeV3(obs, code);
                const callBack = `https://${environment.config.site_uri}/` + this.activatedRoute.snapshot.url.join('/');
                this.getAuthorizationCode(obs, code, callBack);
              })
              .catch(err => console.log(err));
          }
        },
        error: e => console.log('error', e),
        complete: () => console.log('=== end ==='),
      };
    };

    this.auth_$.user.subscribe(buildAuthUserObserver(origin, code));

    this.auth_$.user.subscribe({
      next: async v => {
        if (v) {
          console.log('v: ', v);
          this.handleExistingUser(v);
        } else {
          console.log('No user');
        }
      },
      error: e => console.log('error', e),
      complete: () => console.log('=== end ==='),
    });

    console.log('user is signed in');
  }

  getClientObserver() {
    return {
      next: async (x: any) => await this.handleClientNext(x),
      error: (err: any) => this.handleClientError(err),
      complete: () => console.log('Observer completed'),
    };
  }

  getCalendarObserver() {
    return {
      next: async x => {
        await this.auth_$.userReady(this.auth_$.userData.value, 'getCalendarObserver').catch(err => {
          console.error(err);
          return;
        });
        this.redirectToHome(true);
        this.createClioWebHooks();
        this.getUserLastSession();
        this.error = false;
        this.errorMessage = '';
      },
      error: err => {
        console.error(err);
        const message = 'There was an error, please try again.';
        const label = null;
        this.uiMessaging_$.toastMessage(message, label);
        this.error = true;
        this.errorMessage = 'There was an error, please try to reload this page.';
        this.ref.detectChanges();
      },
      complete: () => console.log('Observer completed'),
    };
  }

  getAddToClioObserver() {
    return {
      next: async x => {
        await this.auth_$.userReady(this.auth_$.userData.value, 'getAddToClioObserver').catch(err => {
          console.error(err);
          return;
        });
        this.redirectToHome(true);
        if (!this.auth_$.isClioRegistered() || !this.auth_$.userData['clioSSO']) {
          this.clio_$.updateClioSSO(0, this.auth_$.userData.getValue()['id']).then(() => {
            if (this.sessionStorage_$.getSSO() === '1') {
              this.sessionStorage_$.setAddToClioStarted(false);
              this.clio_$.completeAddToClioAction();
            } else {
              this.SSOCompleted = true;
            }
          });
        }
        this.error = false;
        this.errorMessage = '';
      },
      error: err => {
        console.error(err);
        const message = 'There was an error, please try again.';
        const label = null;
        this.uiMessaging_$.toastMessage(message, label);
        this.error = true;
        this.errorMessage = 'There was an error, please try to reload this page.';
        this.ref.detectChanges();
      },
      complete: () => console.log('Observer completed'),
    };
  }

  rememberLastSession(event) {
    event.preventDefault();
    this.getUserLastSession();
  }

  handleOpenMeetings(origin: string) {
    console.log('origin :', origin);
    // this.dialog_$.open(OpenMeetingsComponent, {});
    this.router.navigate(['/meetings'], { queryParams: { origin } });
  }

  async getUserLastSession() {
    const lastSession = this.auth_$.userData.value['lastSession'];

    if (!lastSession || lastSession === '') {
      console.log('== no session saved ==');
      return false;
    }

    const { patientDocId, origin } = lastSession;

    if (!patientDocId) {
      return;
    }

    if (origin === 'updateDefaultCalendar') {
      this.handleOpenMeetings(origin);
      return;
    }

    if (origin === 'afterLogin') {
      this.handleOpenMeetings(origin);
      return;
    }

    if (origin === OPEN_APP) {
      this.sessionStorage_$.setOpenAppStarted(true);
      console.log('== handle open app ==');
      return;
    }

    if (origin === OPEN_APP_EU) {
      this.sessionStorage_$.setOpenAppStarted(true);
      console.log('== handle open app ==');
      return;
    }

    const data = (await firebase.firestore().collection('patients').doc(patientDocId).get()).data();
    if (data === undefined) return;
    const FirstName = data.FirstName.charAt(0).toUpperCase() + data.FirstName.slice(1);
    const LastName = (data.LastName = data.LastName.charAt(0).toUpperCase() + data.LastName.slice(1));
    const caseName = data.caseName;
    const LegalCaseId = data.LegalCaseId;
    const DateOfBirth = data.DateOfBirth;
    const sharedUserFiles = data.files;
    const ownerID = data.ownerID;
    const defaultFolders = data.defaultFolders;
    const patient = {
      FirstName,
      LastName,
      caseName,
      LegalCaseId,
      DateOfBirth,
      sharedUserFiles,
      ownerID,
      defaultFolders,
      patientDocId: patientDocId,
      restrictedProfiles: data.restrictedProfiles,
      allowedProfiles: data.allowedProfiles,
    };
    this.routeToProfile(patient);
  }

  routeToProfile(patient) {
    const { ownerID } = patient;
    if (!ownerID) {
      const message = `This profile does not have a valid ownerID, please contact support before retry.`;
      const label = 'ALERT';
      this.uiMessaging_$.toastMessage(message, label);
      return;
    }
    if (!this.windowsManager_$.clientProfileOpened) {
      this.profileDialogRef = this.dialog_$.open(ClientProfileComponent, {
        width: '100vw',
        height: '90vh',
        data: { patient: patient, button: 'clio-matters' },
        id: 'client-profile',
      });
    }
  }

  async handleExistingCodeV2(code) {
    console.log('handleExistingCodeV2');
    const success = async () => {
      console.log(' Handling Existing Code ');

      await this.auth_$.userReady(this.auth_$.userData.value, 'handleExistingCodeV2').catch(err => {
        console.error(err);
        return;
      });

      this.redirectToHome(true);
      this.createClioWebHooks();
      this.getUserLastSession();
      this.error = false;
      this.errorMessage = '';
    };

    const error = err => {
      const message = 'There was an error, please try again.';
      console.error(message, err);
      const label = null;
      this.uiMessaging_$.toastMessage(message, label);
      this.error = true;
      this.errorMessage = 'There was an error, please try to reload this page.';
      this.ref.detectChanges();
    };

    await this.auth_$.userData.toPromise().then(v => {
      if (Object.keys(v).length > 0) this.getAuthorizationCodeV2(success, error, code);
    });
  }

  async handleClientNext(x) {
    console.log('x: ', x);
    this.redirectToHome(true);
    this.createClioWebHooks();
    this.getUserLastSession();
    this.error = false;
    this.errorMessage = '';
  }

  handleClientError(err) {
    console.error(err);
    const message = 'There was an error, please try again.';
    const label = null;
    this.uiMessaging_$.toastMessage(message, label);
    this.error = true;
    this.errorMessage = 'There was an error, please try to reload this page.';
    this.ref.detectChanges();
  }

  async afterClioGetAuthorizationCodeV3(res) {
    console.log('res: ', res);

    // Call UserReady with fresh data from Firestore.
    await this.auth_$.getFreshFirestoreUserData();
    console.log('This.UserData', this.auth_$.userData.getValue());

    if (!this.auth_$.isClioRegistered() || this.auth_$.userData.value['clioSSO'] === 1) {
      // Pasa primero.
      this.clio_$.updateClioSSO(0, this.auth_$.userData.getValue()['id']).then(() => {
        if (this.sessionStorage_$.getSSO() === '1') {
          this.sessionStorage_$.setAddToClioStarted(false);
          this.clio_$.completeAddToClioAction();
        } else {
          this.SSOCompleted = true;
        }
      });
      this.handleCheckReferer('afterClioGetAuthorizationCodeV3:493');
    } else if (this.auth_$.isClioRegistered() && !this.sessionStorage_$['clioSSO']) {
      console.log('🐵');
      console.log('.......', this.activatedRoute.snapshot.queryParams);
      this.handleCheckReferer('afterClioGetAuthorizationCodeV3:497');
    }
  }

  async handleCheckReferer(origin?: string) {
    if (origin) console.log('handleCheckReferer origin: ', origin);

    const referer = decodeURIComponent(decodeURIComponent(this.sessionStorage_$.getReferer()));
    if (referer && referer.split('/').length > 2) {
      const elements = referer.split('/')[1].split('?');
      const action = elements[0];
      const params = elements[1].split('&');

      const queryParams = this.getQueryParams(params);
      console.log('queryParams :', queryParams);
      await this.router.navigate([action], { queryParams }).then(() => this.sessionStorage_$.removeReferer());
      // this.auth_$.preventRedirections = true;
    } else
      await this.router
        .navigate([referer ? referer.split('/')[1] : '/'])
        .then(() => this.sessionStorage_$.removeReferer());
  }

  getAuthorizationCodeV2(success, error, code) {
    // Am I really need to get Authorization Code?
    const clioAccessToken = this.auth_$.userData.value['clioAccessToken'];
    if (clioAccessToken) {
      const { access_token, refresh_token, token_type, expires_in, datetime } = JSON.parse(clioAccessToken);
      const date = new Date(datetime);
      const now = new Date();
      const diff = now.getTime() - date.getTime();
      const diffHours = Math.round(diff / 1000 / 60 / 60);

      if (diffHours < 24) {
        console.log('No need to get Authorization Code');

        this.afterClioGetAuthorizationCodeV3(null).then(() => {
          console.log('success time diffHours < 24');
          // success();
        });
        return;
      }
    }

    const userdocid = this.auth_$.userData.value['id'];
    if (!userdocid) {
      console.log('No userdocid');
      return;
    }

    const eu = this.sessionStorage_$.getAddToClioEU();
    const redirect_uri = `https://${environment.config.site_uri}/${this.activatedRoute.snapshot.url.join('/')}`;
    this.clio_$
      .clioGetAuthorizationCodeV3({ userdocid, code, redirect_uri, eu })
      .then(async res => {
        this.afterClioGetAuthorizationCodeV3(res).then(() => {
          console.log('success time');
          // success();
        });
      })
      .catch(err => {
        console.log('err: ', err);
        error();
      });
  }

  redirectToHome(replaceUrl?: boolean) {
    this.router.navigate(['/'], { replaceUrl });
  }

  private async handleClioAuthCase() {
    const { code } = this.activatedRoute.snapshot.queryParams;
    console.log('queryParams: ', this.activatedRoute.snapshot.queryParams);

    switch (this.activatedRoute.snapshot.params.origin) {
      case ADD_TO_CLIO:
        console.log(ADD_TO_CLIO);
        this.sessionStorage_$.setAddToClioStarted(true);
        // this.redirectToLoginClio();
        break;
      case ADD_TO_CLIO_EU:
        console.log(ADD_TO_CLIO_EU);
        this.sessionStorage_$.setAddToClioStarted(true);
        // this.redirectToLoginClio();
        break;
      case OPEN_APP:
        console.log('OPEN_APP');
        // this.sessionStorage_$.setSSO('0');
        this.sessionStorage_$.removeSSO();
        this.sessionStorage_$.setOpenAppStarted(true);
        this.redirection_$.redirectToLoginClio({ queryParams: { sso: 0 } });
        break;
      case OPEN_APP_EU:
        console.log('OPEN_APP_EU');
        this.sessionStorage_$.removeSSO();
        this.sessionStorage_$.setAddToClioEU(true);
        this.sessionStorage_$.setOpenAppStarted(true);
        this.redirection_$.redirectToLoginClio({ queryParams: { sso: 0 } });
        break;
      case CLIENT:
        console.log('CLIENT');
        if (!code) {
          this.redirectToHome();
        } else {
          this.handleExistingCode(code);
        } // Get Clio Authorization Code
        return;
      case API_AUTH_CALLBACK:
      case API_AUTH_CALLBACK_EU:
        if (!code) this.redirectToHome();
        else this.handleExistingCode(code);
        if (this.sessionStorage_$.getAddToClioStarted() === 'true') this.clio_$.completeAddToClioAction();
        return;
      default:
        console.log('No ClioAuth Actions');
        this.redirection_$.goToLogin();
        break;
    }
  }

  notSignedInActions() {
    const eu = this.sessionStorage_$.getAddToClioEU();
    console.log('notSignedInActions');

    if (this.activatedRoute.snapshot.params.origin === API_AUTH_CALLBACK) {
      console.log(API_AUTH_CALLBACK);
      return;
    }

    if (![SSO_CALLBACK, SSO_CALLBACK_EU].includes(this.activatedRoute.snapshot.url[0].path)) {
      // this.redirection_$.goToLogin();
      // console.log(SSO_CALLBACK);
      if (eu) this.router.navigate(['/login_eu']);
      else this.router.navigate(['/login']);
      return;
    }

    this.auth_$.redirectURL = this.router.routerState.snapshot.url;

    switch (this.activatedRoute.snapshot.url[0].toString()) {
      case CLIO_AUTH:
        console.log(CLIO_AUTH);
        this.handleClioAuthCase();
        break;
      case SSO_CALLBACK:
        console.log(SSO_CALLBACK);
        this.handleRouteMapSubscription();
        break;
      case SSO_CALLBACK_EU:
        console.log(SSO_CALLBACK_EU);
        this.handleRouteMapSubscription(true);
        break;
      case ADD_TO_CLIO:
        console.log(ADD_TO_CLIO);
        this.clio_$.completeAddToClioAction();
        break;
      case ADD_TO_CLIO_EU:
        console.log(ADD_TO_CLIO_EU);
        this.clio_$.completeAddToClioAction();
        break;
      default:
        console.log('No ClioAuth Actions');
        break;
    }
  }

  async signedInActions() {
    console.log('signedInActions');

    const postSuccessUserReady = async () => await this.postSuccessUserReady();

    if (this.activatedRoute.snapshot.queryParams.code) {
      const buildAuthUserObserver = (origin, code) => {
        console.log('origin :', origin);
        let obs;

        switch (origin) {
          case 'calendar':
            obs = this.getCalendarObserver();
            break;
          case CLIENT:
            obs = {
              next: async x => {
                await this.handleClientNext(x);
              },
              error: err => {
                this.handleClientError(err);
              },
              complete: () => {
                console.log('Observer completed');
              },
            };
            break;
          case ADD_TO_CLIO:
            this.sessionStorage_$.setAddToClioStarted(true);
            this.redirection_$.redirectToLoginClio();
            return;
          case ADD_TO_CLIO_EU:
            this.sessionStorage_$.setAddToClioStarted(true);
            this.redirection_$.redirectToLoginClio();
            return;
          default:
            console.log('default: no obs here');
            break;
        }

        return {
          next: async v => {
            if (v !== null && Object.keys(v).length) {
              this.auth_$
                .userReady(v, 'makeSignedInActions')
                .then(async () => this.getAuthorizationCode(obs, code))
                .catch(err => console.log(err));
            }
          },
          error: e => console.log('error', e),
          complete: () => console.log('=== end ==='),
        };
      };

      this.auth_$.user.subscribe(
        buildAuthUserObserver(
          this.activatedRoute.snapshot.params.origin,
          this.activatedRoute.snapshot.queryParams.code,
        ),
      );
    } else {
      switch (this.activatedRoute.snapshot.params.origin) {
        case ADD_TO_CLIO:
          if (this.sessionStorage_$.getSignedIn() === 'true') {
            await this.auth_$.logout(null, false, true);
            this.sessionStorage_$.setAddToClioStarted(true);
          }
          break;
        case ADD_TO_CLIO_EU:
          if (this.sessionStorage_$.getSignedIn() === 'true') {
            await this.auth_$.logout(null, true, true);
            this.sessionStorage_$.setAddToClioStarted(true);
          }
          break;
        default:
          console.log('No ClioAuth Actions');
          break;
      }
    }

    this.auth_$.auth.onAuthStateChanged({
      next: async v => {
        console.log('v: ', v);
        if (v) {
          console.log('v :', v);
          console.log('There is user');

          if (this.activatedRoute.snapshot.params.origin === API_AUTH_CALLBACK) {
            const redirect_uri = `${environment.config.clio.redirect_uri}/${API_AUTH_CALLBACK}`;
            const uid = this.auth_$.auth.currentUser.uid;
            const code = this.activatedRoute.snapshot.queryParams.code;

            if (this.sessionStorage_$.getClioGetAuthorizationCodeV4Executed() !== 'true') {
              this.auth_$.showLoader('Getting Clio Authorization Code...');

              const updateCustomActions = this.sessionStorage_$.getAddToClioStarted() === 'true' ? 1 : 0;
              const eu = this.sessionStorage_$.getAddToClioEU() ? 1 : 0;
              const params = { code, uid, redirect_uri, eu, updateCustomActions };
              await this.clio_$
                .clioGetAuthorizationCodeV7(params)
                .then(async result => {
                  this.sessionStorage_$.setClioGetAuthorizationCodeV4Executed('true');

                  console.log('clioGetAuthorizationCodeV7' + ' result :', result);

                  await this.auth_$.handleGetAuthorizationCodeV3ResultV2(result, this.auth_$.auth.currentUser);

                  if (this.sessionStorage_$.getAddToClioStarted() === 'true') this.clio_$.completeAddToClioAction();
                })
                .catch(err => {
                  console.log('clioGetAuthorizationCodeV7' + ' err :', err);
                  this.auth_$.showLoader('An error has ocurred, please close this window and give it a second try...');

                  if (this.sessionStorage_$.getAddToClioStarted() === 'true') this.clio_$.completeAddToClioAction();
                });
              this.auth_$.hideLoader();
            } else {
              this.auth_$.showLoader('An error has ocurred...');
              setTimeout(() => {
                this.auth_$.hideLoader();
              }, 5000);
            }
          }

          // Run userReady
          this.auth_$
            .userReady(v, 'signedInActions')
            .then(async result => {
              console.log('This is the result of signedInActions :', result);
              await postSuccessUserReady();
            })
            .catch(e => console.log('e :', e));
        } else {
          console.log('No user');
        }
      },
      error: e => console.log('error', e),
      complete: () => console.log('=== end ==='),
    });
  }

  private async postSuccessUserReady() {
    const userEmail = this.auth_$.userData.getValue()['email'];
    if (!this.auth_$.userData.value['clioAccessToken'] && userEmail) await this.populateClioData(userEmail);

    console.log('postSuccessUserReady');
    const isNotClioRegistered = this.auth_$.isClioRegistered() === false;
    const isNotClioSSO = !this.auth_$.userData.value['clioSSO'];
    const isNotTestAccount = !this.auth_$.userData.value['testaccount'];
    const isNotAddToClioStarted = !Boolean(this.sessionStorage_$.getAddToClioStarted());
    const condition = isNotClioSSO || isNotAddToClioStarted;
    const isPlanCodeFP = this.auth_$.userData.getValue()['plancode'] === DEFAULT_PLAN;

    if (isNotClioRegistered && condition && isNotTestAccount && isPlanCodeFP) {
      this.redirection_$.redirectToCompleteClioRegistration();
      return;
    }

    if (!this.activatedRoute.snapshot.queryParams.code) {
      throw new Error('No code parameter');
    }

    if (CLIO_AUTH === this.activatedRoute.snapshot.url[0].path && this.activatedRoute.snapshot.queryParams.code) {
      const success = () => {
        if (
          this.sessionStorage_$.getSSO() === '1' &&
          (!this.auth_$.isClioRegistered() || this.sessionStorage_$.getSSO() === '0') &&
          !this.SSOCompleted
        ) {
          this.clio_$.updateClioSSO(0, this.auth_$.userData.getValue()['id']).then(() => {
            if (this.sessionStorage_$.getSSO() === '1') {
              this.sessionStorage_$.setAddToClioStarted(false);
              this.clio_$.completeAddToClioAction();
            } else {
              this.SSOCompleted = true;
            }
          });
        }
      };

      // Do I need to get Authorization Code at this point?
      const needToGetAuthorizationCodeV2 =
        !this.auth_$.userData.value['clioAccessToken'] ||
        this.validateDateTimeAccessToken(JSON.parse(this.auth_$.userData.value['clioAccessToken'])['datetime']);

      if (needToGetAuthorizationCodeV2) {
        this.getAuthorizationCodeV2(
          success,
          (error: any) => console.log('error', error),
          this.activatedRoute.snapshot.queryParams.code,
        );
        return;
      }

      // This is for SSO process
      const isSSOPRocess = this.sessionStorage_$.getSSO() === '1';
      if (isSSOPRocess) {
        this.sessionStorage_$.setAddToClioStarted(false);
        this.clio_$.completeAddToClioAction();
      }

      this.handleCheckReferer('signedInActions');
    }
  }

  validateDateTimeAccessToken(datetime) {
    if (datetime) {
      const date = new Date(datetime);
      const now = new Date();
      const diff = now.getTime() - date.getTime();
      const diffHours = Math.round(diff / 1000 / 60 / 60);
      if (diffHours < 24) {
        console.log('No need to get Authorization Code');
        return false;
      }
    }
    return true;
  }

  async populateClioData(email) {
    await firebase
      .firestore()
      .collection('users')
      .where('email', '==', email)
      .limit(1)
      .get()
      .then(querySnapshot => {
        const data = querySnapshot.docs[0].data();
        console.log('data: ', data);
        const { clioAccessToken, clioCustomActions } = data;
        const currentUserData = this.auth_$.userData.getValue();
        this.auth_$.userData.next({ ...currentUserData, clioAccessToken, clioCustomActions });
      })
      .catch(e => {
        console.log('e :', e);
      });
  }

  afterSignInWithToken() {
    const uid = this.auth_$.currentUser.getValue()['uid'];
    this.sessionStorage_$.setUID(uid);
    if (this.sessionStorage_$.getAddToClioStarted() === 'true') this.redirectToAuthorize_API_AUTH_CALLBACK();
  }

  afterSignInWithTokenV2(userCredential) {
    console.log('afterSignInWithTokenV2');
    this.sessionStorage_$.setSignedIn(true);

    this.auth_$.userReady(userCredential.user, 'handleActionAfterSigInWithCustomToken').then(({ id }) => {
      this.auth_$
        .setLastSession({ lastSession: { origin: 'clio' } }, id)
        .then(() => {
          // NOTE: Is there a way to know this is EU or US?
          const eu = this.isClioEU();
          const { clientProfile_eu, clientProfile } = environment.config.clio.redirectsGroup;
          this.clio_$.redirectToAuthorize(eu ? clientProfile_eu : clientProfile, eu);
        })
        .catch(err => console.log('err :', err))
        .finally(() => {
          this.auth_$.hideLoader();
          this.sessionStorage_$.removeUID();
          this.sessionStorage_$.removeSSO();
        });
      // this.sessionStorage_$.setClioSSOLogin(true);
    });
  }

  private redirectToAuthorize_API_AUTH_CALLBACK() {
    const eu = this.isClioEU();
    const { clio_us, clio_eu } = environment.config;
    this.clio_$.redirectToAuthorize(eu ? clio_eu.apiAuthCallback : clio_us.apiAuthCallback, eu);
  }

  private getQueryParams(params) {
    const result = {};
    params.forEach((param, index) => (result[param.split('=')[0]] = param.split('=')[1]));
    return result;
  }

  private isClioEU() {
    return this.sessionStorage_$.getAddToClioEU() || this.auth_$.userData.getValue()['clioRegion'] === 'eu' || false;
  }
}
