<app-choose-target-directory *ngIf="targetDirectoryChooser"
                             class="choose-target-directory"

                             [casename]="casename"
                             [currentFolder]="currentFolder"
                             [ngClass]="'floating-menu'"
                             [ngStyle]="getRightClickMenuStyle()"

                             (justQuit)="targetDirectoryChooser = false"
                             (selectFolderAndQuitEv)="onTargetFolderSelection($event, 0)"
                             (selectFolderEv)="onTargetFolderSelection($event, 1)"></app-choose-target-directory>

<app-window-title [closable]="true"
                  [subtitle]="Labels.clientMatterLabel+': ' + casename"
                  [title]="data.title || 'Upload Files or Discs'"></app-window-title>

<div *ngIf="!dataSource.data.length && clioMatterId">
  <app-medical-studies-list></app-medical-studies-list>
</div>

<form class="upload-form"
      novalidate
      [formGroup]="formDoc">
  <div fxLayout="row"
       fxLayoutAlign="space-around center"
       [ngClass]="'margin-bottom-20'">
    <mat-form-field [ngClass]="'hidden-component'">
      <app-input-file #fileBrowser

                      label="Choose file(s)"
                      placeholder

                      formControlName="basicfile"
                      uploadFileType=".gif,.jpg,.tiff,.bmp,.vbo,.mp3,.wav,.mp4,.pdf,.xls,.xlsx,.doc,.docx,.ppt,.pptx,.csv"

                      [allowDirs]="false"
                      [directory]="false"
                      [multiple]="false"
                      [ngClass]="'file-browser'"
                      (change)="handleFileSelection($event)">
      </app-input-file>
      <br>
    </mat-form-field>
    <button *ngIf="!section && section !== 'upload_dicom_disk'"

            color="warn"
            type="button"
            mat-flat-button

            [disabled]="editingNode || !chooseFiles"
            [ngClass]="'browse-button'"
            (click)="browseFiles($event, fileBrowser.ngControl.name)">
      <mat-icon matSuffix>
        description
      </mat-icon>
      Browse your desktop for files
    </button>

    <mat-form-field [ngClass]="'hidden-component'">
      <app-input-file #folderBrowser

                      accept="{{ uploadFileType }}"
                      label="Choose disk or folder"
                      placeholder
                      formControlName="basicfolder"

                      [allowDirs]="true"
                      [directory]="true"
                      [multiple]="true"
                      [ngClass]="'folder-browser'"
                      (change)="handleFolderSelection($event)">
      </app-input-file>
      <br>
    </mat-form-field>
    <button *ngIf="treeControl.dataNodes.length === 0"
            #uploadDiskButton

            color="warn"
            type="button"
            mat-flat-button

            [disabled]="editingNode"
            [ngClass]="'browse-button'"
            (click)="browseFiles($event, folderBrowser.ngControl.name)">
      <mat-icon matSuffix>
        album
      </mat-icon>
      Browse your desktop for discs to upload additional medical images
    </button>
  </div>

  <p *ngIf="!section && section !== 'upload_dicom_disk'"
     class="mat-small"
     [innerHtml]="allowedExtensionsMessage"></p>

  <!-- LIST COMPONENT -->
  <mat-dialog-content>
    <mat-tree #matTreeControl
              [dataSource]="dataSource"
              [treeControl]="treeControl">
      <mat-tree-node *matTreeNodeDef="let node"
                     #treeNode
                     fxLayoutAlign="space-between center"
                     fxLayoutGap="10px"
                     matTreeNodePadding
                     [ngClass]="{ 'edit-mode': node.edit, 'in-progress': node.inprogress, treenode: true, selected: node.selected }">
        <mat-checkbox class="example-margin"
                      [checked]="node.toUpload !== false"
                      [value]="node"
                      (change)="checkNode($event, node)"></mat-checkbox>

        <div fxLayout="column"
             [fxFlex]="node.edit ? 100 : 80">
          <span [ngClass]="node.edit ? 'mat-title' : 'mat-small'">
            <b class="file-title">
              {{node.name}}
            </b>
          </span>

          <div>
            <div fxLayout="column">
              <span *ngIf="!node.edit"
                    [ngClass]="'mat-small'">
                <b *ngIf="node.name === dicomdirFileName">
                  Date of study:
                </b>
                <b *ngIf="node.name !== dicomdirFileName">
                  Date of file
                </b>
                <span *ngIf="node.fdate === ''"
                      [ngClass]="'red-text'">
                  *
                </span>
                :
                {{ node.fdate }}
              </span>

              <span *ngIf="!node.edit"
                    [ngClass]="'mat-small'">
                <b>
                  Content
                </b>
                <span *ngIf="node.desc === ''"
                      [ngClass]="'red-text'">
                  *
                </span>
                : {{ node.desc }}
              </span>

              <span *ngIf="!node.edit && changeFolderPermission"
                    [ngClass]="'mat-small'">
                <b>
                  Folder
                </b>
                <span *ngIf="!node.target.folderId"
                      [ngClass]="'red-text'">
                  *
                </span>
                : {{ node.target.folderName }}
              </span>
            </div>

            <!-- EDIT FORM -->

            <form action
                  fxLayout="column">
              <div fxLayout="row">
                <!-- FILE DATE FIELD -->
                <mat-form-field *ngIf="node.edit"
                                color="primary"
                                appearance="fill"
                                fxFlex="25">
                  <mat-label>
                    Date of file
                  </mat-label>
                  <input #datepicker

                         autocomplete="off"
                         color="primary"
                         required
                         matInput

                         [disabled]="!node.edit"
                         [matDatepicker]="picker"
                         [value]="getDateFromString(node.fdate).value"
                         (dateInput)="
                      fieldInputChange_date($event, node);
                      checkEmpty(datepicker);
                      setFocusToNextControl(datepicker);
                      evaluateCompletion(node)
                    ">
                  <mat-datepicker-toggle matSuffix
                                         [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>

                <!-- FILE CONTENT FIELD -->
                <mat-form-field *ngIf="node.edit"
                                color="primary"
                                appearance="fill"
                                fxFlex="30">
                  <mat-label>
                    File Content
                  </mat-label>
                  <input #filecontent

                         autocomplete="off"
                         color="primary"
                         placeholder="Enter some text"
                         required
                         matInput

                         [value]="node.desc"
                         (keyup)="fieldInputChange_content($event, node); checkEmpty(filecontent); evaluateCompletion(node)">
                </mat-form-field>

                <!-- CHOOSE/CHANGE FOLDER BUTTON -->
                <button *ngIf="changeFolderPermission && node.target && node.edit"
                        #folderBtn
                        type="button"

                        aria-label="Click here to set the folder for this file."
                        fxFlex="45"
                        mat-icon-button

                        [matTooltip]="node.target.folderName ? 'Change folder' : 'Choose folder.'"
                        [ngClass]="{
                    'folder-btn': true,
                    'special-icon-button': true,
                    'highlighted-control': node.fdate !== '' && node.desc !== '' && node.target.folderId === ''
                  }"
                        (click)="selectNode(node)">
                  <mat-icon>
                    folder
                  </mat-icon>
                  <label>
                    {{node.target.folderName ? 'Change folder' : 'Choose folder.'}}
                  </label>
                  <input id="folderChooser_{{ node.ugid }}"
                         class="folder-name"
                         name="folderName"
                         size="20"
                         type="text"
                         [value]="node.target.folderName"
                         (input)="evaluateCompletion(node)">
                </button>
              </div>

              <div fxLayout="row"
                   fxLayoutAlign="start center"
                   fxLayoutGap="10px">
                <button *ngIf="node.edit"

                        color="basic"
                        type="button"

                        aria-label="Click here to save."
                        mat-button
                        mat-flat-button
                        matTooltip="Save the changes."

                        [color]="node.desc !== '' && node.fdate !== '' && node.target.folderId ? 'warn' : 'basic'"
                        [ngClass]="{
                    'special-icon-button': true,
                    'highlighted-control': node.desc !== '' && node.fdate !== '' && node.target.folderId
                  }"
                        (click)="saveGroup(node)">
                  <mat-icon>
                    check
                  </mat-icon>
                  SAVE FILE
                </button>

                <button *ngIf="node.edit"

                        color="basic"
                        type="button"

                        aria-label="Cancel edit."
                        mat-button
                        mat-flat-button
                        mat-flat-button
                        matTooltip="Cancel the edition."
                        (click)="cancelEdit(node)">
                  <mat-icon>
                    close
                  </mat-icon>
                  CANCEL
                </button>

                <button *ngIf="node.edit"

                        color="basic"
                        type="button"

                        aria-label="Click here to delete this upload."
                        mat-button
                        mat-flat-button
                        mat-flat-button
                        matTooltip="Click here to delete this upload."
                        (click)="editingNode = false; nodeFxLayout = 'row'; deleteNode($event, node)">
                  <mat-icon>
                    delete
                  </mat-icon>
                  DELETE
                </button>
              </div>
            </form>
          </div>
        </div>

        <div fxFlex="nogrow"
             fxFlex="20"
             fxLayout="row"
             fxLayoutAlign="space-between center"
             [ngClass]="{ 'action-buttons-disabled': node.edit }">
          <button type="button"

                  aria-label="Click here to edit this upload."
                  mat-flat-button
                  matTooltip="Edit this file."

                  [disabled]="node.edit"
                  [ngClass]="{ 'highlighted-control': node.fdate === '' || node.desc === '', 'special-icon-button': true }"
                  (click)="gotoEdit(node)">
            <mat-icon>
              create
            </mat-icon>
          </button>

          <button type="button"

                  aria-label="Click here to delete this upload."
                  mat-icon-button
                  matTooltip="Click here to delete this upload."

                  [disabled]="node.edit"
                  (click)="deleteChild($event, node)">
            <mat-icon>
              delete
            </mat-icon>
          </button>
        </div>
      </mat-tree-node>

      <mat-tree-node *matTreeNodeDef="let node; when: hasChild"
                     #treeNode
                     fxLayoutAlign="space-between start"
                     matTreeNodePadding
                     [ngClass]="{ 'edit-mode': node.edit, grouphead: true }">
        <button type="button"
                fxFlex="none"
                mat-icon-button
                matTreeNodeToggle
                [attr.aria-label]="'Toggle ' + node.name">
          <mat-icon [ngClass]="'mat-icon-rtl-mirror'">
            {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
          </mat-icon>
        </button>

        <div fxFlex
             fxLayout="column">
          <span>
            <b>
              Group:
            </b>
            {{ node.name }}
          </span>
          <span>
            {{node.desc}}
          </span>
        </div>

        <div fxFlex="nogrow"
             fxLayout="row">
          <button *ngIf="!node.edit"
                  type="button"
                  aria-label="Click here to delete this group."
                  mat-icon-button
                  matTooltip="Click here to delete this group."
                  (click)="deleteGroup(node.ugid)">
            <mat-icon>
              delete
            </mat-icon>
          </button>
        </div>
      </mat-tree-node>
      x
    </mat-tree>
  </mat-dialog-content>
  <!-- end LIST COMPONENT -->
</form>

<p *ngIf="(nodeInEdit && inEditUnComplete) || (!runValidations() && dataSource.data.length > 0 && !nodeInEdit)"
   [innerHtml]="requiredFieldsValidationMessage | safeHtml"
   [ngClass]="'message mat-body'"></p>

<div fxLayout="row"
     fxLayoutAlign="start center"
     fxLayoutGap="10px"
     [ngClass]="'md-actions'">
  <button *ngIf="dataSource.data.length > 0"
          color="warn"

          mat-flat-button
          matTooltip="Click to start to upload the files."

          [disabled]="!runValidations() || editingNode || !filesWithoutTarget() || clicked"
          (click)="handleUploadClick($event)">
    {{ clicked ? 'Uploading...' : getUploadButtonLabel() }}
  </button>
  <div fxLayout="row"
       fxLayoutAlign="start center">
    <mat-spinner *ngIf="clicked"

                 class="spinner"
                 color="warn"

                 diameter="20"
                 mode="indeterminate"
                 strokeWidth="5"></mat-spinner>
    <span *ngIf="clicked"
          [ngClass]="'mat-body red-text'">
      Please wait...
    </span>
  </div>
</div>
