export const Labels = {
  clientMatterLabel: `Patient Profiles`,
  attorneysLabel: `Attorneys`,
  reportLabel: `Generate Report`,
  libraryLabel: `Library`,
  ownerLabel: `Create Primary Hospital`,
  adminLabel: `Medical Records Dept.`,
  clientUsersLabel: `Patients users list`,
  ownerListLabel: `Owners list`,
  consultantListLabel: `Consultant list`,
  associateListLabel: `Medical Staff list`,
  createClientMatterLabel: `Create Patient Profile`,
  createUserLabel: `Create User`,
  videoConferencingLabel: `Video Conferencing`,
  upgradeYourPlanLabel: `Upgrade your plan`,
  auditLogLabel: `Audit Log`,
  logoutLabel: `Logout`,
};
