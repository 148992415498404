<div class="patients-list-container">
  <h3>
    Select a patient
  </h3>
  <div *ngIf="patients.length > 0">
    <div class="patients-list">
      <mat-selection-list #patientsList>
        <mat-list-option *ngFor="let patient of patients"
                         [value]="patient">
          <div class="patient-info">
            <strong>
              Name:
            </strong>
            {{ patient.name }}
          </div>
          <div class="patient-info">
            <strong>
              Age:
            </strong>
            {{ patient.age }}
          </div>
          <div class="patient-info">
            <strong>
              Last Visit:
            </strong>
            {{ patient.lastVisit | date:'mediumDate' }}
          </div>
        </mat-list-option>
      </mat-selection-list>

      <div class="patients-list-actions">
        <button color="accent"
                mat-raised-button
                [disabled]="patientsList.selectedOptions.selected.length === 0"
                (click)="shareWith(patientsList.selectedOptions.selected[0].value)">
          Share files
        </button>
      </div>
    </div>
  </div>
</div>
