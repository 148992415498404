import { Component, Inject, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Labels } from 'src/app/dictionaries/labels';

@Component({
  selector: 'app-files-comparison',
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
      .item-exists {
        color: #228b22 !important;
      }

      .mat-list-option,
      .mat-list-item {
        min-height: 25px;
        height: auto !important;
      }

      .mat-list-container .mat-list-item-content {
        font-size: 14px !important;
        direction: rtl !important;
      }

      .mat-list-container .mat-list-item-content .mat-list-text {
        text-align: left !important;
        padding-left: 15px !important;
      }

      .buttons-row {
        margin-top: 20px;
      }

      .mat-list-items-container {
        max-height: 300px;
        overflow-y: auto;
      }
    `,
  ],
  template: `
    <app-window-title
      title="Select which documents to share"
      subtitle=""
      [closable]="true"></app-window-title>
    <app-client-matter-info [matterData]="matterData"></app-client-matter-info>
    <div fxLayout="row">
      <mat-selection-list
        fxFlex="50"
        class="mat-list-container"
        #list>
        <div mat-subheader>Clio Matter documents not shared to NuageDx</div>
        <div class="mat-list-items-container">
          <ng-container *ngFor="let file of sourceDocuments">
            <mat-list-option
              *ngIf="!file.existing"
              [value]="file.id"
              >{{ file.name }}</mat-list-option
            >
          </ng-container>
        </div>
      </mat-selection-list>
      <mat-list
        fxFlex="50"
        role="list">
        <div mat-subheader>NuageDx {{ Labels.clientMatterLabel }} documents shared from Clio</div>
        <div class="mat-list-items-container">
          <mat-list-item
            class="item-exists"
            *ngFor="let file of localDocuments"
            role="listitem"
            >{{ file.fileName }}</mat-list-item
          >
        </div>
        <mat-list-item
          class="item-exists"
          *ngIf="localDocuments?.length === 0"
          role="listitem"
          >No documents shared yet.</mat-list-item
        >
      </mat-list>
    </div>
    <div
      fxLayout="row"
      fxLayoutAlign="space-evenly center"
      class="buttons-row">
      <button
        mat-flat-button
        (click)="handleImportSelected()"
        color="warn">
        <mat-icon>done</mat-icon>
        Share Selected
      </button>
      <button
        mat-flat-button
        (click)="handleImportAll()"
        color="warn">
        <mat-icon>done_all</mat-icon>
        Share All
      </button>
      <button
        mat-flat-button
        (click)="continueWithoutAction()"
        color="warn">
        <mat-icon>block</mat-icon>
        Continue without sharing
      </button>
    </div>
  `,
})
export class FilesComparisonComponent {
  Labels = Labels;
  sourceDocuments: any[];
  localDocuments: any[];
  matterData = [];
  @ViewChild('list') list;

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<FilesComparisonComponent>,
  ) {
    this.matterData.push({ label: 'Matter', value: data.matterData.lpmFields.matter.display_number });

    this.sourceDocuments = data.options.clioDocumentId
      ? data.sourceDocuments.filter(i => i.id === parseInt(data.options.clioDocumentId, 10))
      : data.sourceDocuments;

    this.sourceDocuments.forEach(file => {
      if (data.localDocuments.find(localFile => localFile.clioDocId === file.id)) {
        file['existing'] = true;
      }
    });

    // If all sourceDocuments are existing, go and close the dialog.
    if (this.sourceDocuments.every(file => data.localDocuments.find(localFile => localFile.clioDocId === file.id))) {
      console.log('All sourceDocuments are existing. Closing the dialog.');
      this.dialogRef.close();
    }

    this.localDocuments = data.localDocuments;
  }

  continueWithoutAction() {
    this.dialogRef.close();
  }

  handleImportSelected() {
    const selected = this.list.selectedOptions.selected.map((item: any) => {
      const { id, name } = this.sourceDocuments.find(file => file.id === item.value);
      return { id, name };
    });
    this.dialogRef.close(selected);
  }

  handleImportAll() {
    this.list.selectAll();
    this.handleImportSelected();
  }
}
