<div style="height: 100vh; display: flex; flex-direction: column;">
  <div style="width: 100%; margin-bottom: 20px;">
    <h2>
      Library
    </h2>
    <div *ngIf="uploadedFiles.length > 0">
      <div style="max-height: 400px; overflow-y: auto; width: 100%;">
        <table class="mat-elevation-z8"
               style="width: 100%;"
               mat-table
               [dataSource]="uploadedFiles">
          <ng-container matColumnDef="select">
            <th *matHeaderCellDef
                style="width: 40px;"
                mat-header-cell>
              <mat-checkbox (change)="selectAll()"></mat-checkbox>
            </th>
            <td *matCellDef="let file"
                style="width: 40px;"
                mat-cell>
              <mat-checkbox [(ngModel)]="file.selected"
                            (change)="toggleSelection(file,$event)"></mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="name">
            <th *matHeaderCellDef
                mat-header-cell>
              Name
            </th>
            <td *matCellDef="let file"
                mat-cell>
              {{ file.name }}
            </td>
          </ng-container>

          <ng-container matColumnDef="size">
            <th *matHeaderCellDef
                mat-header-cell>
              Size
            </th>
            <td *matCellDef="let file"
                mat-cell>
              {{ file.size }} bytes
            </td>
          </ng-container>

          <ng-container matColumnDef="uploadedDate">
            <th *matHeaderCellDef
                mat-header-cell>
              Uploaded Date
            </th>
            <td *matCellDef="let file"
                mat-cell>
              {{ file.uploadedDate | date:'mediumDate' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th *matHeaderCellDef
                mat-header-cell>
              Actions
            </th>
            <td *matCellDef="let file"
                mat-cell>
              <button color="warn"
                      mat-button
                      (click)="deleteFile(file)">
                Delete
              </button>

            </td>
          </ng-container>

          <tbody>
            <tr *matHeaderRowDef="['select','name', 'size', 'uploadedDate', 'actions']"
                mat-header-row></tr>
            <tr *matRowDef="let row; columns: ['select','name', 'size', 'uploadedDate', 'actions'];"
                mat-row></tr>
          </tbody>
        </table>
      </div>
      <div style="margin-top: 20px; text-align: right;">
        <button color="accent"
                mat-raised-button
                [disabled]="isShareButtonDisabled()"
                (click)="copyFileTo()">
          {{ selectedFiles.length > 1 ? 'Copy files to' : 'Copy file to' }}
        </button>
      </div>
    </div>
  </div>
</div>
