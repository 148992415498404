import { Component, Inject, NgZone, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import firebase from 'firebase/app';
import { UserRoles } from 'src/app/dictionaries/UserRoles';

import { DialogService } from 'src/app/dialog.service';
import { SelectOwnerComponent } from 'src/app/select-owner/select-owner.component';
import { User } from '../../models/User';
import { AuthService } from '../../services/auth.service';
import { UIMessagingService } from '../../services/uimessaging.service';
import Validation from '../providers/CustomValidators';
import { FirebaseUtilitiesService } from './../../services/firebase-utilities.service';
import { SimpleMessageWindowComponent } from './../ui/simple-message-window/simple-message-window.component';
import { UpgradePlanComponent } from './../upgrade-plan/upgrade-plan.component';

@Component({
  selector: 'app-createuser',
  templateUrl: './createuser.component.html',
  styleUrls: ['./createuser.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CreateuserComponent implements OnInit {
  currentUser: firebase.User;
  userType: String;
  curentUserRole: any;
  consultantPasswordMessageHTML = `<p><b class="red-text">Please note:</b> If the consultant already exists, the provided password will be disregarded. The consultant can continue to log in using their existing password.</p>`;
  UserRoles: {
    owner: string;
    admin: string;
    associate: string;
    consultant: string;
    superuser: string;
  };
  clientForm: FormGroup;
  radioOptions: { value: string; label: string; prefix: string; disabled: boolean }[];
  submitApproval = false;
  submitted = false;
  passwordStrength: number;
  @ViewChild('passwordComponent') passwordComponent: any;
  generalError: any;
  whitespace = new RegExp(/^[^\\s]*$/);
  limits: any;
  blockedForm: boolean = false;
  consultantPasswordMessage: boolean;
  ownerID: string;

  constructor(
    public auth_$: AuthService,
    public router: Router,
    public ng: NgZone,
    public dialog: MatDialog,
    private dialog_$: DialogService,
    public dialogRef: MatDialogRef<CreateuserComponent>,
    private firebaseUtilitiesService: FirebaseUtilitiesService,
    private uiMessaging_$: UIMessagingService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.UserRoles = {
      owner: UserRoles.owner,
      admin: UserRoles.admin,
      associate: UserRoles.associate,
      consultant: UserRoles.consultant,
      superuser: UserRoles.superuser,
    };
    this.userType = 'User';
    this.limits = this.data.limits;
  }

  async getOwnerID(userRole: string): Promise<string> {
    if (userRole === UserRoles.owner) return this.currentUser.uid;
    else return await this.auth_$.getOwnerID(this.currentUser.uid);
  }

  async createUser() {
    let user;

    if (!this.ownerID)
      this.uiMessaging_$.toastMessage('ownerID is missing. Please reopen this window and try again.', 'FIX');

    const ownerID = this.ownerID;

    if (!ownerID) {
      const message = 'ownerID is missing';
      const label = 'FIX';
      this.uiMessaging_$.toastMessage(message, label);

      return new Error(message);
    }

    const role = this.getUserRoleByUserType(this.clientForm.value.usertype);
    const { email, name, password } = this.clientForm.value;
    user = new User(role, email.toLowerCase(), name, '', ownerID, [ownerID]);
    const createdBy = this.currentUser.uid;
    return this.auth_$.createUserAndSendPasswordResetEmail(password, user, createdBy);
  }

  async updateConsultantUser() {
    let user;
    const userRole = await this.auth_$.getUserRole(this.currentUser.uid);
    const ownerID =
      userRole === UserRoles.owner ? this.currentUser.uid : await this.auth_$.getOwnerID(this.currentUser.uid);

    if (!ownerID) {
      const message = 'ownerID is missing';
      const label = 'FIX';
      this.uiMessaging_$.toastMessage(message, label);

      return new Error('ownerID is missing');
    }

    const { email, name, password, usertype } = this.clientForm.value;
    const role = this.getUserRoleByUserType(usertype);
    const uid = '';
    user = new User(role, email.toLowerCase(), name, uid, ownerID, [ownerID]);
    return this.auth_$.updateConsultantUser(email.toLowerCase(), ownerID);
  }

  private getUserRoleByUserType(userType: string) {
    if (userType === UserRoles.associate.toLowerCase()) {
      return UserRoles.associate;
    }
    if (userType === 'admin') {
      return UserRoles.admin;
    }
    if (userType === UserRoles.consultant.toLowerCase()) {
      return UserRoles.consultant;
    }
  }

  getErrorMessage(formControl, controlname?: string, num?) {
    if (formControl.invalid) {
      if (formControl.errors.required) {
        return controlname === 'usertype' ? `You need to select a user type` : `You need to provide ${controlname}.`;
      }
      if (formControl.errors.minlength) {
        return `The field ${controlname} requires at least ${formControl.errors.minlength.requiredLength} characters length.`;
      }
      if (formControl.errors.email) {
        return `A valid email address is required.`;
      }
      if (formControl.errors.whitespace) {
        return `Please avoid blank spaces.`;
      }
      if (formControl.errors.mustMatch) {
        return `Password and confirm password doesn't match.`;
      }
      if (formControl.errors.passwordStrength) {
        return `Password is not strong enough.`;
      }
    } else {
      return;
    }
  }

  handleUpgradeAnswer(val) {
    if (val === 'thanks') {
      this.dialog_$.open(SimpleMessageWindowComponent, {
        width: '350px',
        data: { title: 'Thanks!!', message: '<p>Your account has been upgraded.</p>' },
      });
      return;
    }

    if (val !== 'upgrade') return;

    const dialogConfig = {
      width: '950px',
      data: {
        title: 'Upgrade Plan',
        currentPlan: this.auth_$.userData.getValue()['plan']['id'],
        message: '<p>Go and upgrade your current plan.</p>',
      },
    };

    const dialogRef = this.dialog_$.open(UpgradePlanComponent, dialogConfig);
    dialogRef.afterClosed().subscribe({
      next: value => this.handleUpgradeAnswer(value),
      error: err => console.warn(err),
      complete: () => console.log('Completed'),
    });
  }

  private async getOwnersData(owners: string[]) {
    const ownersData = [];
    for (const owner of owners) {
      const ownerData = (await this.auth_$.getUserDataByUID(owner)).docs[0].data().map(a => ({
        name: a.name,
        firstName: a.firstName,
        lastName: a.lastName,
      }));
      const name = `${ownerData.firstName} ${ownerData.lastName}(${ownerData.name})`;
      ownersData.push({ label: name, value: owner });
    }
    return ownersData;
  }

  private async selectOwnerBeforeCreateUser() {
    const dialogConfig = {
      width: '350px',
      data: {
        title: 'Select Owner',
        message: '<p>You have more than one owner, please select one.</p>',
        owners: await this.getOwnersData(this.auth_$.userData.getValue()['owners']),
        buttons: [{ text: 'OK', value: 'ok' }],
      },
    };

    const dialogRef = this.dialog_$.open(SelectOwnerComponent, dialogConfig);
    dialogRef.afterClosed().subscribe({
      next: value => this.handleSelectOwnerAnswer(value),
      error: err => console.warn(err),
      complete: () => console.log('Completed'),
    });
  }

  private handleSelectOwnerAnswer(val) {
    this.ownerID = val;
  }

  async ngOnInit() {
    this.passwordStrength = 0;

    this.radioOptions = [
      { value: 'associate', label: 'Medical Staff', prefix: 'an', disabled: true },
      { value: 'admin', label: 'Medical Records Dept.', prefix: 'an', disabled: false },
      { value: 'consultant', label: 'Consultant', prefix: 'a', disabled: false },
    ];

    this.curentUserRole = this.auth_$.userData.getValue()['role'];

    if (this.curentUserRole === UserRoles.owner) this.ownerID = this.auth_$.uid;
    else {
      const owners = this.auth_$.userData.getValue()['owners'];
      if (owners.length === 1) this.ownerID = owners[0];
      else this.selectOwnerBeforeCreateUser();
    }

    if (this.curentUserRole === UserRoles.consultant) {
      this.ng.run(() => this.router.navigateByUrl('/'));
    } else if (this.curentUserRole !== UserRoles.admin && this.curentUserRole !== UserRoles.superuser) {
      // this.onCheckedAccount('User');
      this.onUserTypeChange('user');
    } else if ([UserRoles.admin, UserRoles.superuser].includes(this.curentUserRole)) {
      // this.onCheckedAccount('Consultant');
      this.onUserTypeChange('consultant');
    }

    if (this.curentUserRole !== UserRoles.owner)
      this.radioOptions = [{ value: 'consultant', label: 'Consultant', prefix: 'a', disabled: false }];

    this.clientForm = new FormGroup(
      {
        name: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.email, Validators.required]),
        usertype: new FormControl('', Validators.required),
        passwordconfirm: new FormControl('', [Validators.required]),
        password: new FormControl('', [
          Validators.required,
          Validators.minLength(4),
          () => Validation.passwordStrength(this.passwordStrength),
        ]),
      },
      Validation.mustMatch('password', 'passwordconfirm'),
    );

    this.clientForm.controls.password.valueChanges.subscribe({
      next: value => {
        if (value && value.indexOf(' ') >= 0) {
          this.clientForm.controls.password.setErrors({ whitespace: true }, { emitEvent: false });
        } else {
          return;
        }
      },
    });

    this.auth_$.user.subscribe(async user => {
      if (user !== null && user !== undefined) {
        this.currentUser = user;
      } else if (user === undefined && user === null) {
        document.getElementById('nuageMenu').style.display = 'none';
        this.router.navigateByUrl('/');
      }
    });
  }

  onStrengthChanged(event) {
    this.passwordStrength = event;
    this.clientForm.controls['password'].updateValueAndValidity();
    this.submitApproval =
      event === 100 && this.clientForm.controls.passwordconfirm.value === this.clientForm.controls.password.value
        ? true
        : false;
  }

  async onSubmit() {
    const showOptions = () => {
      if (document.querySelector('#options-group')) {
        document.querySelector('#options-group').classList.remove('hidden');
      }
    };

    this.submitted = true;
    if (this.clientForm.invalid) {
      this.auth_$.errorAuth.next('The clientForm is invalid');
      return;
    }

    if (![UserRoles.consultant, ''].includes(this.clientForm.value.usertype)) {
      const isAbleToCreateUser = await this.firebaseUtilitiesService.validateUserCreation();

      if (!isAbleToCreateUser) {
        this.auth_$.errorAuth.next('You can not create a user, isAbleToCreateUser is false');
        const message = 'You can not create a user due to your current plan';
        const label = null;

        this.uiMessaging_$.toastMessage(message, label);
        this.showRequireUpgradeWindow();

        return false;
      }
    }

    showOptions();

    if (this.clientForm.invalid) {
      return;
    }

    this.showLoader();

    let results;

    const isConsultant = this.clientForm.value.usertype === UserRoles.consultant.toLowerCase();
    if (isConsultant) {
      // First, check if the user exists.
      const consultantExists = await this.auth_$.checkIfConsultantUserExists(this.clientForm.value.email);
      if (consultantExists) {
        results = await this.updateConsultantUser();
      } else {
        results = await this.createUser();
      }
    } else {
      results = await this.createUser();
    }

    this.hideLoader();

    if (results && results.code) {
      this.auth_$.errorAuth.next(results.message);
      this.generalError = results.message;
      return;
    }

    this.dialogRef.close(true);
  }

  showLoader() {
    this.auth_$.showLoader();
  }

  hideLoader() {
    this.auth_$.hideLoader();
  }

  showRequireUpgradeWindow() {
    const dialogConfig = {
      width: '350px',
      data: {
        title: 'Upgrade Plan',

        message:
          '<p>You have reached your limit of users with your current plan.  You can add users by upgrading your plan.</p>',
        buttons: [{ text: 'PLAN UPGRADE', value: 'upgrade' }],
      },
    };

    const dialogRef = this.dialog_$.open(SimpleMessageWindowComponent, dialogConfig);
    dialogRef.afterClosed().subscribe({
      next: val => this.handleUpgradeAnswer(val),
      error: err => console.warn(err),
      complete: () => console.log('Completed'),
    });
  }

  onUserTypeChange(evt) {
    if (evt === 'user') return;
    if (evt) {
      if (evt.value === '') return;
      const selectedRole = evt.value;
      this.evaluateErrors(selectedRole);
      this.consultantPasswordMessage = selectedRole.toLowerCase() === UserRoles.consultant.toLowerCase() ? true : false;
    }
    this.userType = this.setUserType(evt.value);
  }

  setUserType(role) {
    switch (role) {
      case 'associate':
        return 'Medical Staff';
      case 'admin':
        return 'Medical Records Dept.';
      case 'consultant':
        return 'Consultant';
      default:
        return 'User';
    }
  }

  private evaluateErrors(role) {
    const { limits, users } = this.limits.limits;
    const sRole = role;
    if (users[sRole].length >= limits.users[sRole]) {
      this.showRequireUpgradeWindow();
      this.blockedForm = true;
    } else this.blockedForm = false;
  }

  roleToString(role: string) {
    switch (role) {
      case 'Associate':
        return 'associate';
      case 'Client Admin':
      case 'Admin':
        return 'admin';
      case 'Consultant':
        return 'consultant';
      case 'Client':
        return 'client';
      default:
        return '';
    }
  }
}
