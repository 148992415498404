<mat-chip-list aria-label="User Information"
               selectable="false">
  <mat-chip *ngIf="patient.caseName"
            color="primary"
            [ngClass]="['mat-body', 'case-name']">
    <b>
      {{Labels.clientMatterLabel}} id:
    </b>
    &nbsp;{{ patient.caseName }}
  </mat-chip>
  <mat-chip *ngIf="patient.LegalCaseId"
            disableRipple="true"
            [ngClass]="'mat-body'">
    {{Labels.clientMatterLabel}} ID - {{ patient.LegalCaseId }}
  </mat-chip>
  <mat-chip *ngIf="patient.DateOfBirth"
            color="warn"
            disableRipple="true"
            [ngClass]="'mat-body'">
    DOB - {{ patient.DateOfBirth }}
  </mat-chip>
  <mat-chip *ngIf="patient.clientUser && patient.clientUser.length > 0 && !['silver', 'gold'].includes(plancode)"
            color="warn">
    <b>
      Client user:
    </b>
    &nbsp;{{ patient.clientUser }}
  </mat-chip>
</mat-chip-list>
