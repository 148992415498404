import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import firebase from 'firebase';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FilesService {
  public filesArray = new BehaviorSubject([]);
  async getFilesByCaseName(casename: any, docs: boolean) {
    const docsData = (await firebase.firestore().collection('files').where('belongsTo', '==', casename).get()).docs;
    return docsData.length ? <firebase.firestore.DocumentData>docsData
          .map(doc => <firebase.firestore.DocumentData>{ ...doc.data(), id: doc.id })
          .map(file => ({
            ...file,
            fdate: file.fdate ? this.formatDate(file.fdate) : '',
            uploadedDate: file.uploadedDate ? this.formatDate(file.uploadedDate) : '',
            lastModified: file.lastModified ? this.formatDate(file.lastModified) : '',
          })) : [];
  }
  removeDownloadLinkFromFile(fileId: string) {
    return firebase
      .firestore()
      .collection('files')
      .where('fileId', '==', fileId)
      .get()
      .then(({ docs }) => {
        docs[0].ref.update({ downloadLink: null });
      });
  }
  getFileByDICOMDIRFileId(fileId: string) {
    return firebase.firestore().collection('files').where('DICOMDIRFileId', '==', fileId).limit(1).get();
  }
  deleteDownloadLink(fileId: any) {
    return firebase
      .firestore()
      .collection('files')
      .where('fileId', '==', fileId)
      .get()
      .then(({ docs }) => {
        docs[0].ref.update({ downloadLink: null });
      });
  }

  updateDeletedZippedDIscContentsFile(DICOMDIRFileId: any) {
    return firebase
      .firestore()
      .collection('files')
      .where('DICOMDIRFileId', '==', DICOMDIRFileId)
      .get()
      .then(({ docs }) => {
        // Delete the field DICOMDIRFileId.
        docs.forEach(doc => {
          doc.ref.update({ DICOMDIRFileId: null });
        });
      });
  }

  getParentFolderByFileId(fileId: string) {
    return firebase
      .firestore()
      .collection('files')
      .where('fileId', '==', fileId)
      .limit(1)
      .get()
      .then(({ docs }) => docs[0].data().parentFolder);
  }

  getZippedFileByDDICOMDIRFileId(fileId: any) {
    return firebase
      .firestore()
      .collection('files')
      .where('fileId', '==', 'zippedDisc_' + fileId)
      .limit(1)
      .get()
      .then(({ docs }) => {
        return docs[0].data();
      })
      .catch(err => {
        console.log(err);
        throw err;
      });
  }

  async deleteFolder(folderId: string) {
    const { docs } = await firebase.firestore().collection('files').where('folderId', '==', folderId).get();
    return docs.forEach(doc => {
      doc.ref.delete();
    });
  }

  async checkFolderIsEmpty(folderId: string) {
    const { docs } = await firebase.firestore().collection('files').where('parentFolder', '==', folderId).get();
    return docs.length === 0;
  }

  deleteGoogleStorageFile(filePath: string, storageBucket: string) {
    console.log('filePath: ', filePath);
    console.log('storageBucket: ', storageBucket);
    return firebase.functions().httpsCallable('firebaseUtilities-fileDeleteFromStorageV2')({
      filePaths: [filePath],
      bucket: storageBucket,
    });
  }

  async deleteFileByFileId(fileId: string) {
    const { docs } = await firebase.firestore().collection('files').where('fileId', '==', fileId).get();
    return docs.forEach(doc => doc.ref.delete());
  }

  async getFolderLabel(folderId: string, folderName: string, { isConsultant, useremail, isClient }) {
    let count = 0;
    if (isConsultant || isClient) {
      const filesInThisFolder = (
        await firebase
          .firestore()
          .collection('files')
          .where('parentFolder', '==', folderId)
          .where('ftype', '==', 'File')
          .get()
      ).docs;

      if (filesInThisFolder.length === 0) {
        return folderName;
      }

      filesInThisFolder
        .map(file => file.data())
        .forEach(file => {
          file.sharedUsers?.forEach(({ email }) => {
            if (email.toLowerCase() === useremail.toLowerCase()) {
              count++;
            }
          });
        });
    } else {
      count = (await firebase.firestore().collection('files').where('parentFolder', '==', folderId).get()).size;
    }
    return `${this.findFolderAlias(folderName)} (${count})`;
  }

  findFolderAlias(folderName) {
    const foldersAliases = [
      {
        name: 'Discs',
        alias: 'Discs files',
      },
    ];
    return foldersAliases.find(f => f.name === folderName)?.alias || folderName;
  }

  getFileUrl(filePath: string) {
    return firebase.functions().httpsCallable('firebaseUtilities-fileGetSignedURL')({ filePath });
  }

  async getFilesByParentFolderId(folderId: any, casename: any, authoredBy?, roleConsultant = false, email = null) {
    const filterFilesOnly = ({ type }) => type !== 'folder';
    const filterByParentFolderId = ({ parentFolder, parentFolders }) => {
      if (parentFolder === folderId) return true;
      if (parentFolders && parentFolders.some((folder: { folderId: string }) => folder.folderId === folderId))
        return true;
      return false;
    };

    let files = !authoredBy
      ? (await firebase.firestore().collection('files').where('belongsTo', '==', casename).get()).docs
      : (
          await firebase
            .firestore()
            .collection('files')
            .where('belongsTo', '==', casename)
            .where('creator', '==', authoredBy)
            .get()
        ).docs;

    let resultFiles = files
      .map(
        (file: { data: () => firebase.firestore.DocumentData; id: string }) =>
          <firebase.firestore.DocumentData>{ ...file.data(), id: file.id },
      )
      .filter(filterFilesOnly)
      .filter(filterByParentFolderId)
      .map(file => {
        const fdate = file.fdate ? this.formatDate(file.fdate) : '';
        const uploadedDate = file.uploadedDate ? this.formatDate(file.uploadedDate) : '';
        const lastModified = file.lastModified ? this.formatDate(file.lastModified) : '';
        return { ...file, fdate, uploadedDate, lastModified };
      });

    if (roleConsultant) {
      resultFiles = resultFiles.filter(
        file =>
          file['sharedUsers'] && file['sharedUsers'].some(user => user.email.toLowerCase() === email.toLowerCase()),
      );
    }

    return resultFiles;
  }

  formatDate(dateString) {
    return new Date(dateString).toLocaleDateString('en-EN');
  }

  getOtherFolderId(casename: string) {
    return firebase
      .firestore()
      .collection('files')
      .where('belongsTo', '==', casename)
      .where('type', '==', 'folder')
      .where('name', '==', 'Other')
      .get()
      .then(({ docs }) => docs[0].data().folderId);
  }

  public addCurrentPatientFile(file) {
    const newArr = this.filesArray.getValue().push(file);
    this.filesArray.next([newArr]);
  }

  public addPatientFiles(filesArr) {
    this.filesArray.next(filesArr);
  }

  public getFilesArray() {
    return this.filesArray.getValue();
  }

  updateFileByDocId(docId: string, data: any) {
    return firebase.firestore().collection('files').doc(docId).update(data);
  }

  getFileByFileId(fileId: string) {
    return firebase.firestore().collection('files').where('fileId', '==', fileId).limit(1).get();
  }

  getFilesByFileIdsArr(fileIdsArr: string[]) {
    return firebase.firestore().collection('files').where('fileId', 'in', fileIdsArr).get();
  }

  getFilesByFolderId(folderId: string) {
    return firebase.firestore().collection('files').where('folderId', '==', folderId).get();
  }

  async deleteFileByDocId(file) {
    await firebase.firestore().collection('files').doc(file.id).delete();
    return { fileId: file.fileId, docId: file.id };
  }

  private async ungroupFilesIds(filesIds: string[]) {
    const chunkSize = 10;
    const groups = [];

    for (let g = 0; g < filesIds.length; g++) {
      const group: string[] = [];

      for (let h = 0; h < chunkSize; h++) {
        if (!filesIds[h + g]) {
          continue;
        }

        group.push(filesIds[h + g]);
      }
      groups.push(group);
      g += chunkSize;
    }
    return groups;
  }

  async getParentFolders(filesIDs: string[]): Promise<string[]> {
    if (filesIDs.length > 10) {
      const groups: any[] = await this.ungroupFilesIds(filesIDs);
      let arr: string[] = [];

      for (var h = 0; h < groups.length; h++) {
        const folderIDs = await this.getParentFolderIDsByFileIDs(groups[h]);
        arr = [...arr, ...folderIDs];
      }

      return arr;
    } else {
      return this.getParentFolderIDsByFileIDs(filesIDs);
    }
  }

  private async getParentFolderIDsByFileIDs(fileIds: string[]): Promise<string[]> {
    return firebase
      .firestore()
      .collection('files')
      .where('fileId', 'in', fileIds)
      .get()
      .then(qS => {
        const folderIDs: string[] = qS.docs.map(doc => <string>doc.data().parentFolder);
        return folderIDs;
      });
  }

  async getFilesCountByClient(casename: string, roleConsultant, consultantEmail: string) {
    const { docs } = await firebase.firestore().collection('files').where('belongsTo', '==', casename).get();
    const items = docs.map(item => item.data()).filter(({ type }) => type !== 'folder');
    if (!roleConsultant) {
      return items.length;
    } else {
      return items.filter(
        ({ sharedUsers }) =>
          sharedUsers && sharedUsers.map(({ email }) => email.toLowerCase()).includes(consultantEmail.toLowerCase()),
      ).length;
    }
  }

  updateFileField(fileId: string, field: any) {
    firebase
      .firestore()
      .collection('files')
      .where('fileId', '==', fileId)
      .limit(1)
      .get()
      .then(({ docs }) => {
        docs[0].ref.update(field);
      });
  }

  deleteFilesFromStorage(filePaths: string[]) {
    const bucket = `${environment.config.projectId}.appspot.com`;
    return firebase.functions().httpsCallable('firebaseUtilities-fileDeleteFromStorage')({ bucket, filePaths });
  }
}
