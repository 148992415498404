import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import firebase from 'firebase/app';
import moment from 'moment';
import { UserRoles } from 'src/app/dictionaries/UserRoles';
import { Labels } from 'src/app/dictionaries/labels';

import { AuthService } from '../../services/auth.service';
import { UIMessagingService } from '../../services/uimessaging.service';
import { MatDatepicker } from '@angular/material/datepicker';

const dbUsers = firebase.firestore().collection('users');
@Component({
  selector: 'app-createpatient',
  templateUrl: './createpatient.component.html',
  styleUrls: ['./createpatient.component.css'],
})
export class CreatepatientComponent implements OnInit {
  @ViewChild('picker') datepicker: MatDatepicker<any>;
  Labels = Labels;
  gender: string = 'none';
  fname: string;
  lname: string;
  patientId: string;
  legalCaseId: string;
  medicalRecordNumber: string;
  description: string;
  emailAddress: string;
  cellPhoneNumber: string;
  errorMessage: string;
  date: FormControl = new FormControl();
  ownerID: any;
  UserRoles: { owner: string; admin: string; associate: string; consultant: string; superuser: string };
  maxDate = new Date();
  constructor(
    public auth_$: AuthService,
    public router: Router,
    public ng: NgZone,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CreatepatientComponent>,
    private uiMessaging_$: UIMessagingService,
  ) {
    this.UserRoles = {
      owner: UserRoles.owner,
      admin: UserRoles.admin,
      associate: UserRoles.associate,
      consultant: UserRoles.consultant,
      superuser: UserRoles.superuser,
    };
    this.fname = '';
    this.lname = '';
    this.patientId = '';
    this.legalCaseId = '';
    this.medicalRecordNumber = '';
    this.errorMessage = '';
    this.cellPhoneNumber = '';
    this.emailAddress = '';
    this.description = '';
  }

  async ngOnInit() {
    this.authInit();
    this.auth_$.errorAuth.subscribe(error => {
      this.errorMessage = error;
    });

    const currentUserUID = this.auth_$.currentUser.value.uid;
    const userobj = await dbUsers
      .where('uid', '==', currentUserUID)
      .limit(1)
      .get()
      .then(item => ({
        role: item.docs[0].data().role,
        createdBy: item.docs[0].data().createdBy,
        ownerID: item.docs[0].data().ownerID,
      }));
    if (userobj.role === UserRoles.owner) {
      this.ownerID = currentUserUID;
    } else if (userobj.ownerID) {
      this.ownerID = userobj.ownerID;
    } else {
      const message = `The current user has an invalid ownerID, please contact your administrator before retry`;
      const label = 'ALERT';
      this.uiMessaging_$.toastMessage(message, label);
    }
  }

  openDatepickerIfEmpty() {
    // Open the datepicker if the date is empty
    if (!this.date.value) {
      this.datepicker.select(new Date());
      this.datepicker.open();
    }
  }

  authInit() {
    this.auth_$.user.subscribe(user => {
      if (user !== null && user !== undefined) {
        const db = firebase.firestore();

        db.collection('users')
          .where('uid', '==', user.uid)
          .get()
          .then(querySnapshot => {
            querySnapshot.forEach(doc => {
              if (doc.data()['role'] !== UserRoles.client && doc.data()['role'] !== UserRoles.admin) {
                this.determineRoute(doc.data()['role'], user);
              }
            });
          });
      }
    });
  }

  cleanBlankSpaces(str: string) {
    return str.replace(/[^(A-Za-z0-9)]/g, '-');
  }

  createPatient() {
    if (this.validateFields()) {
      this.auth_$.firePatientCantAdd();
    } else {
      this.handleCreatePatient();
    }
  }

  private handleCreatePatient() {
    const dateValue = this.date.value;
    const dateString = dateValue ? this.getDateString(moment(this.date.value).add(1, 'M').toDate()) : null;

    this.auth_$
      .createPatientMedical(
        this.fname,
        this.lname.toLowerCase(),
        this.medicalRecordNumber.toLowerCase().replace(/ /g, '-'),
        this.medicalRecordNumber,
        dateString,
        this.ownerID,
        {
          cellphonNumber: this.cellPhoneNumber,
          emailAddress: this.emailAddress,
          description: this.description,
          gender: this.gender,
        },
      )
      .then(result => {
        console.log(result);
        if (result) {
          this.dialogRef.close();
        }
      });
  }

  private validateFields() {
    return (
      this.fname.trim() === '' ||
      this.lname.trim() === '' ||
      this.cleanBlankSpaces(this.medicalRecordNumber.toLowerCase()) === ''
    );
  }

  determineRoute(role: any, { uid, email }) {
    let url = '/';
    if (role === UserRoles.consultant) {
      url = `search/${uid}/${email}/${role}`;
    }
    this.ng.run(() => this.router.navigateByUrl(url));
  }

  goBack() {
    window.history.back();
  }

  private getDateString(date: Date) {
    return `${date.getMonth().toString()}/${date.getDate().toString()}/${date.getFullYear().toString()}`;
  }
}
