import { ErrorHandler } from '@angular/core';
import rg4js from 'raygun4js';

// Create a new ErrorHandler and report an issue straight to Raygun
export class RaygunErrorHandler implements ErrorHandler {
  handleError(e: any): void {
    if (window.location.hostname === 'medical.nuagedx.com') {
      rg4js('send', { error: e });
    }
    console.error(e);
  }
}
