import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-patients-list',
  templateUrl: './patients-list.component.html',
  styleUrls: ['./patients-list.component.scss'],
})
export class PatientsListComponent implements OnInit {
  shareWith(arg0: any) {
    throw new Error('Method not implemented.');
  }
  @Input() patients: any[] = [];

  constructor() {}

  loadPatients() {
    // Implement the method to load patients
    // set dummy patients data.
    this.patients = [
      {
        id: 1,
        name: 'John Doe',
        age: 25,
      },
      {
        id: 2,
        name: 'Jane Doe',
        age: 30,
      },
      {
        id: 3,
        name: 'Alice',
        age: 35,
      },
      {
        id: 4,
        name: 'Bob',
        age: 40,
      },
    ];
  }

  ngOnInit() {
    this.loadPatients();
  }

  viewPatientDetails(patient: any) {
    // Implement the method to view patient details
  }
}
