<div *ngIf="error"
     class="paragraph">
  {{ errorMessage }}
  <a href="#"
     (click)="reloadThisPage($event)">
    Reload
  </a>
  |
  <a href="#"
     (click)="rememberLastSession($event)">
    Back to {{Labels.clientMatterLabel}} Profile
  </a>
</div>
