import 'firebase/firestore';
import 'firebase/storage';

import { Injectable } from '@angular/core';
import firebase from 'firebase/app';
import { BehaviorSubject, from, of, zip } from 'rxjs';
import { distinct, groupBy, map, mergeMap, toArray } from 'rxjs/operators';
import { UserRoles } from 'src/app/dictionaries/UserRoles';

import { HttpParams, HttpRequest } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ClioAccessTokenDialogComponent } from '../components/clio/clio-access-token-dialog/clio-access-token-dialog.component';
import { DialogService } from '../dialog.service';
import { CustomActions } from '../dictionaries/CustomActions';
import { NotificationData } from '../models/NotificationData';
import { NotificationTypes } from '../models/NotificationTypes';
import { UserData } from '../notifications.service';
import { LogLevelsDictionary } from './../dictionaries/LogLevels';
import { LogService } from './../log.service';
import { Client } from './../models/Client';
import { Folder } from './../models/Folder';
import { AlgoliaService } from './algolia.service';
import { AuthService } from './auth.service';
import { FilesService } from './files.service';
import { UIMessagingService } from './uimessaging.service';
import { UtilsService } from './utils.service';

const dbPatients = firebase.firestore().collection('patients');
const dbFiles = firebase.firestore().collection('files');
const dbPlans = firebase.firestore().collection('plans');
const dbFeatures = firebase.firestore().collection('features');
const dbUsers = firebase.firestore().collection('users');
const dbDefaultFolders = firebase.firestore().collection('defaultFolders');
const CLIO_FOLDER_NAME = 'Clio';
const freePlanMeetingsLimit = 4;

interface CreateNotificationsParams {
  type: number;
  uploadBlock: { casename: string };
  noteId?: string;
  targetUser?: User;
}

interface MenuItem {
  name: string;
  uid: string;
  clients: any[];
}

interface CreateFileObjectParams {
  caseName: any;
  creator: any;
  path: any;
  studyuid: any;
  entryMeta: any;
  uploadTypes: any;
  type: any;
  up_datastorename: any;
  entry: any;
  lastModified: any;
  parentFolder: any;
  parentFolderId: any;
  scanAnalysisId: any;
  uploadedDate: any;
}

export interface User {
  id: string;
  email: string;
  role: string;
  uid: string;
}

export interface Notification {
  userId: any;
  type: string;
  fileId: string;
  fileType: 'file' | 'dicom';
  folderId: string;
  caseId: string;
  userRole: any;
}

@Injectable({
  providedIn: 'root',
})
export class FirebaseUtilitiesService {
  getFeatures() {
    return dbFeatures.get().then(res => {
      return res.docs.map(doc => doc.data());
    });
  }

  getPlans() {
    return dbPlans
      .where('active', '==', true)
      .get()
      .then(res => res.docs.map(doc => ({ ...doc.data(), id: doc.id })));
  }

  increasePlanCounter(ownerID: any, arg1: string) {
    return dbUsers
      .where('uid', '==', ownerID)
      .get()
      .then(res => {
        const data = res.docs[0].data();
        const newValue = (data[arg1] || 0) + 1;
        return dbUsers.doc(res.docs[0].id).update({ [arg1]: newValue });
      });
  }

  getOwnerTotalStudies(ownerID: any) {
    return dbUsers
      .where('uid', '==', ownerID)
      .get()
      .then(res => {
        return res.docs[0].data().totalStudies;
      });
  }

  increaseOwnerTotalStudies(ownerID: any, totalStudies: number) {
    return dbUsers
      .where('uid', '==', ownerID)
      .get()
      .then(res => {
        const data = res.docs[0].data();
        const newValue = data.totalStudies || 0 + totalStudies;
        return dbUsers.doc(res.docs[0].id).update({ totalStudies: newValue });
      });
  }

  async validatePlanLimits(ownerID: any, planID: any) {
    const getZoomMeetingsCounter = (await dbUsers.where('uid', '==', ownerID).get()).docs[0].data().zoomMeetingsCreated;
    // If the plan is 'free' and the counter is greater than 2, return false
    // FIXME: Check the limit HERE
    return (planID === 'free' || planID === 'fp') && getZoomMeetingsCounter >= freePlanMeetingsLimit ? false : true;
  }

  db = firebase.firestore();
  algoliaObjects: any[] = [];
  UserRoles: { owner: string; admin: string; associate: string; consultant: string; superuser: string };
  public infectedFiles = new BehaviorSubject<File[]>([]);

  constructor(
    public auth_$: AuthService,
    private files_$: FilesService,
    public logService_$: LogService,
    private utils_$: UtilsService,
    private dialog_$: DialogService,
    private uiMessaging_$: UIMessagingService,
    private algolia_$: AlgoliaService, // private notifications_$: NotificationsService,
  ) {
    this.UserRoles = {
      owner: UserRoles.owner,
      admin: UserRoles.admin,
      associate: UserRoles.associate,
      consultant: UserRoles.consultant,
      superuser: UserRoles.superuser,
    };
  }

  async getPatientDocIdFromCaseName(casename: any) {
    return (
      await firebase
        .firestore()
        .collection('patients')
        .where('caseName', '==', casename)
        .limit(1)
        .get()
        .catch(err => {
          console.log('err :', err);
          throw err;
        })
    ).docs[0].id;
  }

  async getDefaultFolders(casename: string) {
    return (
      await dbFiles
        .where('belongsTo', '==', casename)
        .where('type', '==', 'folder')
        .where('predefined', '==', true)
        .get()
        .catch(err => {
          console.log('err :', err);
          throw err;
        })
    ).docs.map(c => c.data());
  }

  async getDefaultFoldersDefinition(userRole: string) {
    return (
      await dbDefaultFolders
        .where('restricted', 'array-contains', userRole)
        .get()
        .catch(err => {
          console.log('err :', err);
          throw err;
        })
    ).docs.map(c => c.data());
  }

  /**
   * List users under an owner UID grouped by type of user.
   */
  async getAllUsersByOwnerUIDGrouped(uid: any) {
    const data = (
      await dbUsers
        .where('owners', 'array-contains', uid)
        .where('disabled', '==', false)
        .get()
        .catch(err => {
          console.log('err :', err);
          throw err;
        })
    ).docs
      .map(i => ({ email: i.data().email, role: i.data().role, name: i.data().name }))
      .sort((a, b) => a.role.localeCompare(b.role));

    return from(data).pipe(
      groupBy(user => user.role),
      mergeMap(group => zip(of(group.key), group.pipe(toArray()))),
      map(arr => ({ role: arr[0], emails: arr[1].map(e => ({ name: e.name, email: e.email })) })),
    );
  }

  updateUserSettings(userSettings: string, userdocid: string) {
    return dbUsers.doc(userdocid).update({ settings: userSettings });
  }

  async getAllFolders(casename: string) {
    const folders = (
      await firebase
        .firestore()
        .collection('files')
        .where('type', '==', 'folder')
        .where('belongsTo', '==', casename)
        .get()
        .catch(err => {
          console.log('err :', err);
          throw err;
        })
    ).docs.map(i => i.data());
    return folders;
  }

  async getCaseOwnerId(casename: string): Promise<string> {
    return (
      await dbPatients
        .where('caseName', '==', casename)
        .limit(1)
        .get()
        .catch(err => {
          console.log('err :', err);
          throw err;
        })
    ).docs[0].data().ownerID;
  }

  /**
   * Creates a special row in files collection. This record is only to describe a folder.
   * Have to specify child elements ids and parent folder if any, root (top level) is by default.
   */

  async getPlan(plandocid) {
    return (await dbPlans.doc(plandocid).get()).data();
  }

  async getUserDocId(uid: string) {
    const qs = await this.auth_$._getUserByUID(uid);
    return qs.docs[0].id;
  }

  async getUserInfoByEmail(useremail: any) {
    return (
      await dbUsers
        .where('email', '==', useremail)
        .limit(1)
        .get()
        .catch(err => {
          console.log('err :', err);
          throw err;
        })
    ).docs[0].data();
  }

  async createFolder(folderObject: Folder) {
    const { type, children, parentFolder, name, belongsTo, folderId, predefined, color } = folderObject;
    const cleanBelongsTo = belongsTo.replace(/[^a-zA-Z0-9]/g, '');
    const folderClearName = name.replace(/[^a-zA-Z0-9]/g, '');
    const folderName = `d_${cleanBelongsTo}_${folderClearName}`;
    dbFiles.doc(folderName).set({
      type,
      children,
      parentFolder,
      name,
      color,
      predefined,
      belongsTo,
      forDelete: false,
      folderId,
    });
    return dbFiles.doc(folderClearName);
  }

  async checkFolderName(foldername: string) {
    return (
      await firebase
        .firestore()
        .collection('files')
        .where('type', '==', 'folder')
        .where('name', '==', foldername)
        .limit(1)
        .get()
        .catch(err => {
          console.log('err :', err);
          throw err;
        })
    ).docs[0];
  }

  async clearAllSharedFilesByCaseName(caseName: string) {
    (await dbUsers.get()).docs.forEach(item => {
      const filesSharedWith = item.data().filesSharedWith;
      if (filesSharedWith) {
        const fileCaseNameMatch = filesSharedWith.find(({ patientCaseName }) => patientCaseName === caseName);
        if (fileCaseNameMatch) {
          const newFilesSharedWith = filesSharedWith.filter(({ patientCaseName }) => patientCaseName !== caseName);
          dbUsers.doc(item.id).update({ filesSharedWith: newFilesSharedWith });
        }
      }
    });
  }

  async createImportFolder(name: string, color: string, belongsTo: string, folderId: string) {
    const folderObject = {
      name,
      color,
      belongsTo,
      folderId,
      type: 'folder',
      predefined: true,
      forDelete: false,
      children: '',
      parentFolder: '',
    };
    await this.createFolder(folderObject);
    return folderId;
  }

  // FIXME: #198
  async deleteFilesByIds(fileIds) {
    const promisesStack = [];
    (
      await dbFiles
        .where('fileId', 'in', fileIds)
        .get()
        .catch(err => {
          console.log('err :', err);
          throw err;
        })
    ).docs.forEach(doc => {
      promisesStack.push(dbFiles.doc(doc.id).delete());
    });
    return Promise.all(promisesStack);
  }

  async deleteFolder(folderId) {
    // 1. Check its contents.
    // 2. Clear all sharing properties of child elements.
    // 3. Remove folder contents.
    // 4. Remove folder.
  }

  /**
   * Handle physical and logic duplication of selected files into the target folder.
   * @param selected files to be duplicated
   * @param folderId of the target folder.
   */
  async duplicateFilesTo(selected: any[], { folderId, folderName }) {
    const promisesStack = [];
    let newFilesArr = selected.map(file => ({ ...file, newName: this.getNewFileName(file.fileName) }));

    newFilesArr.forEach(file => {
      const splitted = file.filePath.split('/');
      splitted.pop();
      promisesStack.push(
        firebase
          .functions()
          .httpsCallable('files-duplicateFile')({
            fileId: file.fileId,
            bucketSource: environment.config.storageBucket,
            bucketTo: environment.config.storageBucket,
            sourceFile: file.filePath,
            newFileName: `${splitted.join('/')}/${file.newName}`,
          })
          .catch(err => {
            console.log('err :', err);
            return null;
          }),
      );
    });

    const duplicatedStorageFiles = (await Promise.all(promisesStack)).filter(file => file.data !== null);

    // Store all duplicated files in firestore.
    const duplicateInFirestorePromises = duplicatedStorageFiles.map(({ data }) =>
      this.duplicateFirestoreFile(data.fileId, data.filePath, { folderId, folderName }),
    );

    return Promise.all(duplicateInFirestorePromises).then(result => {
      this.algolia_$.storeToAlgolia();
      return result;
    });
  }

  /**
   * Create a new firestore record based on an existing one but with new filePath, parentFolder and fileId.
   */
  async duplicateFirestoreFile(originalFileId: string, filePath: string, { folderId, folderName }) {
    const originalFile = (
      await dbFiles
        .where('fileId', '==', originalFileId)
        .get()
        .catch(err => {
          console.log('err :', err);
          throw err;
        })
    ).docs[0].data();
    const fileId = this.getRandomString(17);
    const objectID = fileId;
    const type = originalFile.type || originalFile.ftype.toLowerCase();
    const parentFolderName = folderName;
    const parentFolder = folderId;
    const fileObject = { ...originalFile, filePath, parentFolder, parentFolderName, fileId, objectID, type };
    const promisesList = [];

    promisesList.push(dbFiles.add(fileObject));
    this.algolia_$.algoliaObjects.push(fileObject);

    // NOTE: Add new file reference to the original file shared UserRoles.
    if (originalFile.sharedUsers?.length > 0) {
      promisesList.push(this.updateSharedUsers(originalFile));
    }
    return Promise.all(promisesList);
  }

  async storeDICOMFileInFirebaseStorage(file, filepath: string, options) {
    const promise = new Promise((resolve, reject) => {
      const uploadTask = firebase.storage().ref().child(filepath).put(file);

      uploadTask.on(
        'state_changed',
        ({ bytesTransferred, totalBytes }) => {
          const percentage = this.roundDecimals((bytesTransferred / totalBytes) * 100, 2);

          if (!options.studyDescription) {
            console.log('File no study description: ', file);
          }

          if (percentage === 100) {
            if (options.counter && options.total) {
              this.handleMessagingV2(true, { percentage, file, counter: options.counter, total: options.total });
            } else {
              this.handleMessaging(true, { percentage, file });
            }

            console.log('==========================================');
            console.log('File uploaded: ' + file.name, filepath);
            console.log('==========================================');
          }
        },
        error => {
          console.log('++++++++++++++++++++++++++++++++++++++++++');
          console.log('Upload task on error: ', error);
          console.log('++++++++++++++++++++++++++++++++++++++++++');
          this.logService_$.log(LogLevelsDictionary.error, error);
          reject(error);
        },
        () => {
          this.handleMessaging(false, { file, percentage: 100 });
          setTimeout(() => resolve(true), 100);
        },
      );
    }).catch(error => {
      console.log('++++++++++++++++++++++++++++++++++++++++++');
      console.log('Error in promise: ', error);
      console.log('++++++++++++++++++++++++++++++++++++++++++');
      return error;
    });
    return promise;
  }

  private handleMessaging = (type, options?) => {
    const { percentage, file } = options;

    if (type === false) return;

    console.log("file['name']", file['name']);
    const message = options.studyDescription
      ? `STUDY: ${options.studyDescription} | ${file['name']}: ${percentage}%.`
      : `${file['name']}: ${percentage}%.`;

    this.uiMessaging_$.toastMessage(message, 'PROGRESS');
    this.logService_$.log(LogLevelsDictionary.info, message);
  };

  private handleMessagingV2 = (type, options?) => {
    const { percentage, file } = options;

    if (type === false) {
      return;
    }

    const message = options.studyDescription
      ? `STUDY: ${options.studyDescription} | ${file['name']}: ${percentage}%.`
      : // : `${file['name']}: ${percentage}%. File ${options.counter} of ${options.total}`;
        `File ${options.counter} of ${options.total} uploaded.`;

    this.uiMessaging_$.toastMessage(message, 'PROGRESS');
    this.logService_$.log(LogLevelsDictionary.info, message);
  };

  private getPercentageFromSnapshot(snapshot) {
    const calc = snapshot.bytesTransferred / snapshot.totalBytes || 0 * 100;
    return this.roundDecimals(calc, 2);
  }

  private handleStateChanged(snapshot, fileName) {
    const { state } = snapshot;
    if (state === 'running') {
      const percentage = this.getPercentageFromSnapshot(snapshot);
      console.log(`${fileName}: ${percentage}%.`);
      this.uiMessaging_$.toastMessage(`${fileName}: ${percentage}%.`, 'PROGRESS');
    }
  }

  private handleErrorOnFileStorageAdd({ code }) {
    switch (code) {
      case 'storage/unauthorized':
        console.log(`User doesn't have permission to access the object`);
        break;
      case 'storage/canceled':
        console.log(`User canceled the upload`);
        break;
      case 'storage/unknown':
        console.log(`Unknown error occurred, inspect error.serverResponse`);
        break;
    }
  }

  private handleUploadCompleted(uploadTask) {
    uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => console.log('File available at', downloadURL));
  }

  /**
   * Adds the file to the File Storage.
   */
  fileStorageAdd(file: any, path: string) {
    // Add file to storage.
    const uploadTask = firebase
      .storage()
      .ref()
      .child(path)
      .put(<File>file.entry);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        'state_changed',
        snapshot => this.handleStateChanged(snapshot, file.entry.name),
        error => {
          this.handleErrorOnFileStorageAdd(error);
          reject(error);
        },
        async () => {
          this.handleUploadCompleted(uploadTask);
          resolve(true);
        },
      );
    });
  }

  async removeFileRegistry(fullpath: any, fileentry: any) {
    const docs = await dbFiles
      .where('filePath', '==', fullpath)
      .get()
      .catch(err => {
        console.log('err :', err);
        throw err;
      });
    const promisesStack = [];
    docs.forEach(doc => promisesStack.push(doc.ref.delete()));
    return Promise.all(promisesStack);
  }

  async fileRegistration({ params, file }) {
    const { scanAnalysisId, filepath, uploadBlock, targetFolder, studyuid, uploadTypes, type, storename, options } =
      params;
    const { folderName, folderId } = targetFolder;
    const { casename, creator } = uploadBlock;
    const { entry, entryMeta, uploadedDate, parentFolderName } = file;
    const lastModified = params.lastModified || new Date(file.lastModified).toString() || '';
    const parentFolder = parentFolderName || folderName;
    const parentFolderId = file.parentFolderId || folderId;

    // File Registration.

    const fileObject = this.createFileObject({
      caseName: casename,
      creator: creator,
      path: filepath,
      studyuid: studyuid,
      entryMeta: entryMeta,
      uploadTypes: uploadTypes,
      type: type,
      up_datastorename: storename,
      entry: entry,
      lastModified: lastModified,
      parentFolder: parentFolder,
      parentFolderId: parentFolderId,
      scanAnalysisId: scanAnalysisId,
      uploadedDate: uploadedDate,
    });

    if (options && options.section === CustomActions.uploadDicomDisk) {
      console.log('Call createClioViewerLinkFile');
      // this.createClioViewerLinkFile(desc, studyuid ? this.viewerurlGenerator(caseName, up_datastorename, studyuid) : '')
    }

    // Add file registry to firestore.
    const newDocId = (await this.addFileToFirestore(fileObject))?.id;
    return { ...fileObject, id: newDocId };
  }

  async createClioViewerLinkFile(desc: any, viewerurl: any, clioMatterId: any) {
    return firebase.functions().httpsCallable('createClioViewerLinkFile')({ desc, viewerurl, clioMatterId });
  }

  async getUsersCount(uid: string) {
    const roles = ['Client Admin', 'Associate'];
    return (
      await firebase
        .firestore()
        .collection('users')
        .where('owners', 'array-contains', uid)
        .where('role', 'in', roles)
        .get()
        .catch(err => {
          console.log('err :', err);
          throw err;
        })
    ).docs.length;
  }

  async getUsersWithActionsLogged(owneruid: string) {
    return (await firebase.firestore().collection('logs').where('owners', 'array-contains', owneruid).get()).docs.map(
      d => d.data(),
    );
  }

  /**
   * This builds the menu items for the filterByUser.
   */
  async getUsersByOwneruid(owneruid: string): Promise<MenuItem[]> {
    // UTILITIES
    const toCapitalCase = sstr => sstr.replace(/([a-z])/i, (str, firstLetter) => firstLetter.toUpperCase());
    /**
     * Getting clients names for ui improvement.
     */
    const getClientsData = async (_owneruid: string) =>
      (
        await dbPatients
          .where('owners', 'array-contains', _owneruid)
          .get()
          .catch(err => {
            console.log('err :', err);
            throw err;
          })
      ).docs
        .map(r => r.data())
        .map(e => ({ name: `${e.FirstName} ${e.LastName}`, ownerID: e.ownerID, casename: e.caseName }));
    const allTheClientsData = await getClientsData(owneruid);
    const getClientName = (clientcasename, allClientsData) =>
      toCapitalCase(allClientsData.find(i => i.casename === clientcasename).name);

    // Filter all the clients who has a log registry from a given user uid/ownerid
    const getClients = (uid: string, source: any[]) => {
      const red = source.filter(i => i.uid === uid);
      const result = [];
      from(red)
        .pipe(
          distinct(g => g.client),
          map(c => ({ uid: c.uid, client: c.client, clientname: getClientName(c.client, allTheClientsData) })),
        )
        .subscribe(g => result.push(g));
      return result;
    };

    // Get all the users who has log registries from a given ownerid.
    const getUsersWithActionsLogged = (await this.getUsersWithActionsLogged(owneruid))
      .filter(e => e.client !== null)
      .map(item => ({ uid: item.uid, client: item.client }));

    // LETS BUILD THE LIST OF CONSULTANTS.
    const consultants: MenuItem[] = [];
    from(getUsersWithActionsLogged)
      .pipe(distinct(n => n.uid))
      .subscribe(async n => {
        const { name, uid } = await this.getUserByUID(n.uid);
        const clients = getClients(uid, getUsersWithActionsLogged);
        consultants.push({ name, uid, clients });
      });

    return consultants;
  }

  async getUserByUID(uid: string): Promise<any> {
    const doc = (await this.auth_$._getUserByUID(uid)).docs[0];
    return { ...doc.data(), id: doc.id };
  }

  async getPlanUsersLimit(plan: { plancode: string }) {
    if (!plan) {
      console.error('There was an issue with getPlanUsersLimit, plan is undefined');
      return;
    }
    const planID = plan.plancode === 'fp' ? 'free' : plan.plancode;
    const planData = (await dbPlans.doc(planID).get()).data();
    return planData['users'];
  }

  async getFileNameByFileId(fileId: string) {
    const obj = (
      await dbFiles
        .where('fileId', '==', fileId)
        .limit(1)
        .get()
        .catch(err => {
          console.log('err :', err);
          throw err;
        })
    ).docs[0];
    return obj ? obj.data()['fileName'] : false;
  }

  async getTwoFactorData(uid: string) {
    return (await this.auth_$._getUserByUID(uid)).docs[0].data();
  }

  async getFolder(foldertype: string, caseName: string) {
    switch (foldertype) {
      case 'clio':
        const folders = (
          await firebase
            .firestore()
            .collection('files')
            .where('type', '==', 'folder')
            .where('name', '==', CLIO_FOLDER_NAME)
            .where('belongsTo', '==', caseName)
            .get()
            .catch(err => {
              console.log('err :', err);
              throw err;
            })
        ).docs;

        if (folders.length) {
          return folders[0].data()['folderId'];
        } else {
          const folderId = this.utils_$.getRandomString_(20);
          await this.createImportFolder(CLIO_FOLDER_NAME, '#1e81b0', caseName, folderId);
          return folderId;
        }

      default:
        return '';
    }
  }

  async getTwoFactorDataByEmail(email: string) {
    let userData;
    return dbUsers
      .where('email', '==', email)
      .limit(1)
      .get()
      .then(sp => {
        userData = sp.docs[0].data();
        return {
          twoFactorSet: userData.twoFactorAuthenticationSet || null,
          twoFactorEnabled: userData.twoFactorEnabled || null,
          twoFactorMethod: userData.twoFactorMethod || null,
        };
      })
      .catch(err => {
        console.log('err :', err);
        throw err;
      });
  }

  /**
   * Examine the given folder and count the number of files inside.
   * @param folderId of the folder to examine.
   * @returns a promise with a count string as end.
   */
  async getNumberOfFilesByFolderId(
    folderId: string = '',
    roleConsultant?: string,
    useremail?: string,
    filesShared?: any[],
  ): Promise<string> {
    const { docs } = await firebase
      .firestore()
      .collection('files')
      .where('parentFolder', '==', folderId)
      .get()
      .catch(err => {
        console.log('err :', err);
        throw err;
      });

    const length =
      roleConsultant && useremail
        ? docs.filter(doc => (areSharedUsers(doc) ? mailIncluded(doc) : false)).length
        : docs.length;

    return length > 0 ? `(${length})` : ``;

    function mailIncluded(doc: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>): unknown {
      return doc
        .data()
        ['sharedUsers'].map(({ email }) => email)
        .includes(useremail);
    }

    function areSharedUsers(doc: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>) {
      return doc.data()['sharedUsers'] && doc.data()['sharedUsers'].length;
    }
  }

  async getPatientNameByCaseName(caseName: string) {
    return (await dbPatients.doc(caseName).get()).data();
  }

  getUserSettings(uid: string) {
    return this.auth_$._getUserByUID(uid).then(res => res.docs[0].data().settings);
  }

  async getCustomGuests(userdocid: string): Promise<any[]> {
    const userSettings = JSON.parse((await dbUsers.doc(userdocid).get()).data()['settings']);
    return userSettings.customGuests || [];
  }

  async getConsultantsList(ownerID: string) {
    return (
      await dbUsers
        .where('role', '==', 'Consultant')
        .where('owners', 'array-contains', ownerID)
        .where('disabled', '==', false)
        .get()
        .catch(err => {
          console.log('err :', err);
          throw err;
        })
    ).docs.map(item => item.data());
  }

  /**
   * Creating a new filename in order to make a copy.
   */
  getNewFileName(fileName: string) {
    const separator = '.';
    const connector = '_';
    let name = fileName;
    let extension = '';
    const splitted = fileName.split(separator);
    if (splitted.length > 1) {
      extension = separator + splitted[splitted.length - 1];
      splitted.pop();
      name = splitted.join(separator);
    }
    return name + connector + Date.now() + Math.random().toString() + extension;
  }

  /**
   * Utility function to generate a unique string.
   * @param stringLength The length of the resulting string.
   * @returns A random string with the given length.
   */
  getRandomString(stringLength: number = 10): string {
    const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz';
    let randomstring = '';
    for (let i = 0; i < stringLength; i++) {
      const rnum = Math.floor(Math.random() * chars.length);
      randomstring += chars.substring(rnum, rnum + 1);
    }
    return randomstring;
  }

  /**
   * Get an array of key paired (name, id) objects containing the folders from a given casename.
   * @param casename limit the result for the give case name only.
   * @returns a list of folder records.
   */
  async getFolders(casename: string): Promise<any> {
    return firebase
      .firestore()
      .collection('files')
      .where('type', '==', 'folder')
      .where('belongsTo', '==', casename)
      .get()
      .catch(err => {
        console.log('err :', err);
        throw err;
      })
      .then(({ docs }) => docs.map(doc => doc.data()));
  }

  private async getPredefinedFoldersV2(casename) {
    return dbFiles
      .where('type', '==', 'folder')
      .where('belongsTo', '==', casename)
      .where('predefined', '==', true)
      .get()
      .catch(err => {
        console.log('err :', err);
        throw err;
      });
  }

  async getPredefinedFolders(casename: string, ownerHasAccess?: boolean): Promise<any> {
    const predefinedFolders = [];
    (await this.getPredefinedFoldersV2(casename)).docs.forEach(doc => predefinedFolders.push(doc.data()));
    if (ownerHasAccess) {
      return predefinedFolders;
    } else {
      return predefinedFolders.filter(folder => folder.name !== 'Client Upload');
    }
  }

  async getDefaultFoldersFlag(casename: string): Promise<boolean> {
    return (
      await dbPatients
        .where('caseName', '==', casename)
        .limit(1)
        .get()
        .catch(err => {
          console.log('err :', err);
          throw err;
        })
    ).docs[0].data().defaultFolders;
  }

  async getCustomFolders(casename, filter?: string): Promise<any[]> {
    let customFolders = [];
    await dbFiles
      .where('type', '==', 'folder')
      .where('belongsTo', '==', casename)
      .where('predefined', '==', false)
      .get()
      .catch(err => {
        console.log('err :', err);
        throw err;
      })
      .then(fileSnapshot => fileSnapshot.forEach(item => customFolders.push(item.data())));
    return this.filterAndSort(customFolders, filter);
  }

  /**
   * Function to sort alphabetically an array of objects by some specific key.
   * @param property Key of the object to sort.
   */
  dynamicSort(property: string) {
    let sortOrder = 1;

    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }

    return (a, b) =>
      sortOrder === -1 ? b[property].localeCompare(a[property]) : a[property].localeCompare(b[property]);
  }

  async getFilesByClientId(clientId) {
    const docs = (
      await firebase
        .firestore()
        .collection('files')
        .where('belongsTo', '==', clientId)
        .get()
        .catch(err => {
          console.log('err :', err);
          throw err;
        })
    ).docs;
    const files = [];
    docs.forEach(doc => files.push(doc.data()));
    return files;
  }

  async getActiveConsultantsByClientId(role: string, clientId: string, clientFiles?: any[]) {
    clientFiles = clientFiles || (await this.getFilesByClientId(clientId));
    const allowedRoles = role ? [role] : ['Consultant'];
    const entries = [];
    const localAllUsers = [];

    for (let index = 0; index < allowedRoles.length; index++) {
      const usersList = (
        await dbUsers
          .where('role', '==', allowedRoles[index])
          .get()
          .catch(err => {
            console.log('err :', err);
            throw err;
          })
      ).docs;

      for (let idx = 0; idx < usersList.length; idx++) {
        const userData = usersList[idx].data();
        const filesArr = Object.values(clientFiles);

        if (typeof userData.filesSharedWith === 'undefined') {
          continue;
        }

        if (!userData.filesSharedWith.length) {
          continue;
        }

        if (!filesArr.length) {
          continue;
        }

        userData.filesSharedWith.forEach(({ fileId, patientCaseName }) => {
          // NOTE: Validate if item exists.
          let itemExists = false;

          for (let ix = 0; ix < filesArr.length; ix++) {
            if (filesArr[ix]['fileId'] === fileId) {
              itemExists = true;
              break;
            }
          }

          if (
            itemExists &&
            patientCaseName === clientId &&
            entries.indexOf(userData.email) === -1 &&
            !userData.disabled
          ) {
            entries.push(userData.email);
            localAllUsers.push(userData);
          }
        });
      }
    }

    return localAllUsers;
  }
  async getActiveConsultantsAndClientByClientId(role: string, clientId: string, ownerId: string, clientFiles?: any[]) {
    clientFiles = clientFiles || (await this.getFilesByClientId(clientId));
    const allowedRoles = role ? [role, 'Client'] : ['Consultant', 'Client'];
    const entries = [];
    const localAllUsers = [];
    const usersList = await this.getUsersByRolesAndOwnerID(allowedRoles, ownerId);

    for (let idx = 0; idx < usersList.length; idx++) {
      const userData = usersList[idx];
      // const userData = usersList[idx].data();
      const filesArr = Object.values(clientFiles);

      if (typeof userData.filesSharedWith === 'undefined') continue;
      if (!userData.filesSharedWith.length) continue;
      if (!filesArr.length) continue;

      userData.filesSharedWith.forEach(({ fileId, patientCaseName }) => {
        // NOTE: Validate if item exists.
        let itemExists = false;

        for (let ix = 0; ix < filesArr.length; ix++) {
          if (filesArr[ix]['fileId'] === fileId) {
            itemExists = true;
            break;
          }
        }

        const conditions =
          itemExists && patientCaseName === clientId && entries.indexOf(userData.email) === -1 && !userData.disabled;

        if (conditions) {
          entries.push(userData.email);
          localAllUsers.push(userData);
        }
      });
    }

    const sortedUsers = this.sortUsersList(localAllUsers, 'Client');
    return sortedUsers;
  }

  getRole(uid: string) {
    if (!uid) {
      console.error('getRole: uid not provided');
      return;
    }
    return new Promise(resolve => {
      this.auth_$._getUserByUID(uid).then(querySnapshot => querySnapshot.forEach(doc => resolve(doc.data().role)));
    });
  }

  /**
   * Returns the email adress of the creator of the given casename.
   */
  async getClientCreatorEmailByCaseName(caseName) {
    const message = `This client has been created for an unauthorized user
        please contact your administrator before proceed with any action.`;
    const userRoles = [UserRoles.consultant, UserRoles.associate, UserRoles.admin, UserRoles.owner];
    const uidOfCreator = await dbPatients
      .where('caseName', '==', caseName)
      .limit(1)
      .get()
      .catch(err => {
        console.log('err :', err);
        throw err;
      })
      .then(item => item.docs[0].data().uidOfCreator);
    const email = await this.auth_$._getUserByUID(uidOfCreator).then(item => {
      const { role, emailstr } = item.docs[0].data();
      if (!userRoles.includes(role)) {
        this.uiMessaging_$.toastMessage(message, 'ALERT');
      }
      return emailstr;
    });
    return email;
  }

  async getPatientSharedFilesByConsultant(patientcasename: string, useremail: any) {
    const files = dbFiles;
    const outputFiles = [];
    const filteredFiles = await files
      .where('belongsTo', '==', patientcasename)
      .get()
      .catch(err => {
        console.log('err :', err);
        throw err;
      });
    for (const file of filteredFiles.docs) {
      const _file = file.data();
      if (_file.sharedUsers?.length > 0) {
        const sharedUsers = _file.sharedUsers
          .filter(sharedUser => sharedUser.email)
          .map(sharedUser => sharedUser.email);
        _file.isShared = sharedUsers.indexOf(useremail.toLowerCase()) > -1 ? 1 : 0;
        outputFiles.push(_file);
      }
    }
    return outputFiles;
  }

  /**
   * Just update the parent folder of selected files.
   * @param selected The selected file to be moved.
   * @param folderId The target folder to move selected into.
   * @returns A promisesStack of the updates.
   */
  async moveFilesTo(selected: any[], folderId: string) {
    const files = selected.map(file => file.fileId);
    const promisesStack = [];
    await dbFiles
      .where('fileId', 'in', files) // FIXME: This will fail with more than 10 files.
      .get()
      .catch(err => {
        console.log('err :', err);
        throw err;
      })
      .then(snapshot =>
        snapshot.docs.forEach(item => promisesStack.push(dbFiles.doc(item.id).update({ parentFolder: folderId }))),
      );

    return Promise.all(promisesStack);
  }

  roundDecimals(num, decimalPlaces) {
    const p = Math.pow(10, decimalPlaces);
    return Math.round(num * p) / p;
  }

  // FIXME: #198 Improve files removal.
  /**
   * (WIP)
   */
  removeFilesByIdsFromUser(fileId, sharedUsersEmail) {
    console.log('fileId: ', fileId);
    sharedUsersEmail.forEach(email => {
      dbUsers
        .where('email', '==', email)
        .get()
        .catch(err => {
          console.log('err :', err);
          throw err;
        })
        .then(docs => {
          docs.forEach(doc => {
            const filesShared = doc.data().filesSharedWith.filter(file => file.fileId !== fileId);
            dbUsers.doc(doc.id).update({ filesSharedWith: filesShared });
          });
        });
    });

    return dbFiles
      .where('fileId', '==', fileId)
      .get()
      .catch(err => {
        console.log('err :', err);
        throw err;
      })
      .then(snapshot =>
        snapshot.docs.forEach(doc =>
          dbFiles.doc(doc.id).update({
            sharedUsers: doc.data().sharedUsers.map(user => sharedUsersEmail.includes(user.email.toLowerCase())),
          }),
        ),
      );
  }

  async setNoFactorAuthSetup(email: string, method?: string, enabled?: boolean, twoFactorAuthSet?: boolean) {
    const user = await dbUsers
      .where('email', '==', email)
      .limit(1)
      .get()
      .catch(err => {
        console.log('err :', err);
        throw err;
      });
    if (user.empty) {
      throw new Error(`User doesn't exist`);
    }

    dbUsers.doc(user.docs[0].id).update({
      twoFactorAuthenticationSet: twoFactorAuthSet || true,
      twoFactorEnabled: enabled || false,
      twoFactorMethod: method || null,
    });
  }

  async setUserCode(code, type, email) {
    const userId = (
      await dbUsers
        .where('email', '==', email)
        .limit(1)
        .get()
        .catch(err => {
          console.log('err :', err);
          throw err;
        })
    ).docs[0].id;
    return dbUsers.doc(userId).update({
      twofactorCode: JSON.stringify({ code: code, type: type }),
      twoFA_timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    });
  }

  async getPatientSharedFilesByConsultantV2(matterId: string, useremail: any, clientId: string) {
    const outputFiles = [];
    const filteredFiles = await dbFiles
      .where('belongsTo', '==', clientId)
      .get()
      .catch(err => {
        console.log('err :', err);
        throw err;
      });
    for (const file of filteredFiles.docs) {
      const _file = file.data();
      if (_file.sharedUsers) {
        const sharedUsers = _file.sharedUsers.map(sharedUser => sharedUser.email);
        _file.isShared = sharedUsers.indexOf(useremail) > -1 ? 1 : 0;
        outputFiles.push(_file);
      }
    }
    return outputFiles;
  }

  /**
   *
   *
   * @param {{ fileId: any; fileName: any }} fileToShare
   * @param {string} useremail
   * @param {string} patientcasename
   * @return {*}
   * @memberof FirebaseUtilitiesService
   */
  async shareFile(fileToShare: { fileId: any; fileName: any }, useremail: string, patientcasename: string) {
    const { userDocId, userUid, userRole } = await this.getDocIdAndData(useremail);

    let { name } = (
      await dbUsers
        .where('email', '==', useremail)
        .get()
        .catch(err => {
          console.log('err :', err);
          throw err;
        })
    ).docs[0].data();

    const doc = (
      await dbFiles
        .where('fileId', '==', fileToShare.fileId)
        .limit(1)
        .get()
        .catch(err => {
          console.log('err :', err);
          throw err;
        })
    ).docs[0];

    const docData = doc.data();

    // Update sharedUsers.
    await this.updateSharedUsersById(doc.id, this.getSharedUsers(docData.sharedUsers, useremail, name));

    this.updateFilesArray(patientcasename, useremail.toLowerCase());
    await this.updateUserSharedWith(fileToShare, useremail.toLowerCase(), patientcasename);

    const message = `Shared files for ${useremail.toLowerCase()} are now updated`;
    console.log('message: ', message);

    this.uiMessaging_$.toastMessage(message, null);
    const targetUser: User = { email: useremail, uid: userUid, role: userRole, id: doc.id };

    // Create notifications.
    this.handleCreateNotifications(doc.id, docData, patientcasename, targetUser);

    return fileToShare.fileName;
  }

  handleCreateNotifications(fileDocId, docData, patientcasename, targetUser: User) {
    const params = {
      type: docData.type === 'file' ? 1 : 2,
      uploadBlock: { casename: patientcasename },
      targetUser,
    };
    const fileData = { id: docData.fileId };
    const file = { parentFolderId: docData.parentFolder };

    this.createNotifications(fileData, params, file, NotificationTypes.sharedfile);
  }

  /**
   *
   *
   * @param {string} email
   * @param {string} [method]
   * @param {boolean} [enabled]
   * @param {boolean} [twoFactorAuthSet]
   * @return {*}
   * @memberof FirebaseUtilitiesService
   */
  async setTwoFactorAuthSetup(email: string, method?: string, enabled?: boolean, twoFactorAuthSet?: boolean) {
    console.log('twoFactorAuthSet: ', twoFactorAuthSet);
    const docId = (
      await dbUsers
        .where('email', '==', email)
        .limit(1)
        .get()
        .catch(err => {
          console.log('err :', err);
          throw err;
        })
    ).docs[0].id;
    try {
      dbUsers.doc(docId).update({
        twoFactorAuthenticationSet: twoFactorAuthSet || true,
        twoFactorEnabled: enabled || false,
        twoFactorMethod: method || null,
      });
    } catch (err) {
      return err;
    } finally {
      return true;
    }
  }

  /**
   *
   *
   * @param {any[]} attachments
   * @param {string} patientName
   * @return {*}  {Promise<string[]>}
   * @memberof FirebaseUtilitiesService
   */
  async storeNoteNewAttachments(
    attachments: any[],
    patientName: string,
  ): Promise<{ filePath: string; description: string }[]> {
    const attachmentData = [];
    const promisesStack = [];

    attachments.forEach(attachment => {
      const filePath = `/patient/${patientName}/${attachment.entry.name}`;
      attachmentData.push({ filePath, description: attachment.description });
      const promise = this.storeAttachment(filePath, attachment.entry, {});
      promisesStack.push(promise);
    });

    // NOTE: Hay que retornar una promesa para leer resultados.
    const result = await Promise.all(promisesStack);
    return attachmentData;
  }

  updateNotesByFile(fileid: string, notesArray: any[]) {
    return dbFiles
      .where('fileId', '==', fileid)
      .limit(1)
      .get()
      .catch(err => {
        console.log('err :', err);
        throw err;
      })
      .then(sp => dbFiles.doc(sp.docs[0].id).update({ notes: notesArray }));
  }

  async updateClient(clientObject: Client) {
    const { CaseName, FirstName, LastName, DateOfBirth } = clientObject;
    const docId = (
      await dbPatients
        .where('caseName', '==', CaseName)
        .limit(1)
        .get()
        .catch(err => {
          console.log('err :', err);
          throw err;
        })
    ).docs[0].id;
    await dbPatients.doc(docId).update({
      FirstName: FirstName,
      LastName: LastName,
      DateOfBirth: DateOfBirth,
    });
  }

  async updateSharedUsers(originalFile) {
    const { sharedUsers, belongsTo, fileId } = originalFile;
    const emails = sharedUsers.map(user => user.email);
    const newFile = [{ fileId, patientCaseName: belongsTo }];
    const sharersQS = await dbUsers
      .where('email', 'in', emails)
      .get()
      .catch(err => {
        console.log('err :', err);
        throw err;
      });
    const promisesList = [];

    sharersQS.forEach(userDoc =>
      promisesList.push(
        dbUsers.doc(userDoc.id).update({ filesSharedWith: firebase.firestore.FieldValue.arrayUnion(...newFile) }),
      ),
    );
    return Promise.all(promisesList);
  }

  /**
   * Updates the Firebase Authentication User Email
   */
  async updateUserAuthEmail(email: string) {
    const user = firebase.auth().currentUser;
    if (user !== null) {
      try {
        user.updateEmail(email);
      } catch (error) {
        return error;
      }
    }
  }

  /**
   * Updates the Firebase Authentication User PhoneNumber
   */
  async updateUserAuthPhoneNumber(phoneNumber: any) {
    const user = firebase.auth().currentUser;
    if (user !== null) {
      try {
        user.updatePhoneNumber(phoneNumber);
      } catch (error) {
        return error;
      }
    }
  }

  /**
   * Updates the Firestore User Email
   */
  async updateUserEmail(email: string) {
    const userDocId = (await this.auth_$._getUserByUID(this.auth_$.uid)).docs[0].id;
    return dbUsers.doc(userDocId).update({ email });
  }

  async updateFilesArray(casename: string, useremail: string) {
    const filesList = await this.getPatientSharedFilesByConsultant(casename, useremail);
    this.files_$.filesArray.next(filesList);
    this.uiMessaging_$.toastMessage('Share successful', null);
  }

  /**
   * Updates the Firestore User Phone number
   */
  async updateUserPhoneNumber(phoneNumber: string) {
    const userDocId = (await this.auth_$._getUserByUID(this.auth_$.uid)).docs[0].id;
    return dbUsers.doc(userDocId).update({ phoneNumber });
  }

  /**
   * Updates both Firestore data email and Authentication data email with the given @param email:string.
   */
  async updateUserAndAuthEmail(email: string): Promise<void> {
    await this.updateUserAuthEmail(email);
    await this.updateUserEmail(email);
  }

  async getUserNameByEmail(email: string) {
    return (
      await dbUsers
        .where('email', '==', email)
        .limit(1)
        .get()
        .catch(err => {
          console.log('err :', err);
          throw err;
        })
    ).docs[0].data().name;
  }

  async getFileDocIdByFileId(fileId: string) {
    return (
      await dbFiles
        .where('fileId', '==', fileId)
        .get()
        .catch(err => {
          console.log('err :', err);
          throw err;
        })
    ).docs[0].id;
  }

  async unShareFile(fileToUnshare, email, patientcasename) {
    const name = await this.getUserNameByEmail(email);
    const fileID = await this.getFileDocIdByFileId(fileToUnshare.fileId);
    const fileObject = { sharedUsers: firebase.firestore.FieldValue.arrayRemove({ email, name }) };
    await dbFiles.doc(fileID).update(fileObject);
    console.log('email :', email);
    await this.updateDeleteUserSharedWith(fileToUnshare, email);

    const message = `Unshare successful of ${fileToUnshare.fileName} and ${email}`;
    this.uiMessaging_$.toastMessage(message, null);
    const sharedFilesByPatientAndConsultant = await this.getPatientSharedFilesByConsultant(
      patientcasename,
      email.toLowerCase(),
    );

    // NOTE: Useful for UNsharing action from CONSULTANTS tab.
    this.files_$.filesArray.next(sharedFilesByPatientAndConsultant);
    return sharedFilesByPatientAndConsultant;
  }

  validateClioAccessToken(token?: string) {
    const clioAccessToken = this.auth_$.userData.getValue()['clioAccessToken'];
    return clioAccessToken ? JSON.parse(clioAccessToken)['access_token'] : false;
  }

  // FIXME: Validate to use EU or US keys.
  // This comes from Update Default Calendar.
  private redirectToClioAuthorize() {
    const eu = this.auth_$.userData.getValue()['clioRegion'] === 'eu' || false;
    const { client_id, redirectsGroup, authorizeURL, authorizeURL_eu } = environment.config.clio;
    const params = new HttpParams()
      .set('client_id', client_id)
      .set('response_type', 'code')
      .set('redirect_uri', redirectsGroup.clientProfile)
      .set('state', this.getRandomString(10));
    const request = new HttpRequest('GET', eu ? authorizeURL_eu : authorizeURL, null, { params });
    window.location.href = request.urlWithParams;
  }

  async updateDefaultCalendar(predefinedCalendar: string, uid: string) {
    switch (predefinedCalendar) {
      case environment.constants.mailClient.CLIO:
        const validationResult = this.validateClioAccessToken(uid);
        if (!validationResult) {
          this.handleGetClioAuthorization(
            {
              origin: 'updateDefaultCalendar',
              message: 'Your default calendar now is CLIO, but you need to Authorize its usage first.',
            },
            this.auth_$.userData['clioAccessToken'],
          );
        } else {
          console.log('The user has already authorized CLIO usage.');
        }
        break;
      default:
        break;
    }

    dbUsers
      .doc(this.auth_$.userData.getValue()['id'])
      .update({ settings: JSON.stringify({ calendar: predefinedCalendar }) })
      .then(updated => console.log('Default calendar updated', updated));

    return { calendar: predefinedCalendar };
  }

  async setDefaultCalendar(predefinedCalendar: string) {
    return dbUsers
      .doc(this.auth_$.userData.getValue()['id'])
      .update({ settings: JSON.stringify({ calendar: predefinedCalendar }) });
  }

  async handleGetClioAuthorization(data, clioAccessToken?: string) {
    this.dialog_$
      .open(ClioAccessTokenDialogComponent, { width: '500px', data })
      .afterClosed()
      .subscribe(async (origin: string) => {
        if (origin === 'updateDefaultCalendar') {
          firebase
            .firestore()
            .collection('users')
            .doc(this.auth_$.userData.value['id'])
            .update({ lastSession: { origin }, client: 'clio' })
            .then(() => {
              if (!clioAccessToken || !JSON.parse(clioAccessToken)['access_token']) {
                this.redirectToClioAuthorize();
              } else {
                console.log('The user has already authorized CLIO usage.');
              }
            })
            .catch(err => console.log('err :', err));
        }
      });
  }

  /**
   * Update the fileSharedWith field with the changes (file: {patientCaseName,fileId}).
   * @param file which has been shared.
   * @param email of the consultant who will have sharing access to it..
   * @param patientcasename the file is inside.
   */
  async updateUserSharedWith(file: any, email: string, patientcasename: string): Promise<void> {
    const user = (
      await dbUsers
        .where('email', '==', email)
        .get()
        .catch(err => {
          console.log('err :', err);
          throw err;
        })
    ).docs[0];
    const newValue = { patientCaseName: patientcasename, fileId: file.fileId };
    return dbUsers.doc(user.id).update({ filesSharedWith: firebase.firestore.FieldValue.arrayUnion(newValue) });
  }

  async updateDeleteUserSharedWith(file: any, email: string) {
    const user = (
      await dbUsers
        .where('email', '==', email)
        .get()
        .catch(err => {
          console.log('err :', err);
          throw err;
        })
    ).docs[0];
    const filesSharedWith = user.data().filesSharedWith.filter(item => item.fileId !== file.fileId);
    await dbUsers.doc(user.id).update({ filesSharedWith });
    this.uiMessaging_$.toastMessage(`Shared files for ${email} are now updated`, null);
  }

  async updateDefaultFolders(defaultFolders: boolean, { casename }): Promise<boolean> {
    const snapshot = await dbPatients
      .where('caseName', '==', casename)
      .limit(1)
      .get()
      .catch(err => {
        console.log('err :', err);
        throw err;
      });
    snapshot.forEach(item => dbPatients.doc(item.id).update({ defaultFolders }));
    return defaultFolders;
  }

  /**
   *
   *
   * @return {*}
   * @memberof FirebaseUtilitiesService
   */
  async validateUserCreation(): Promise<boolean> {
    const { plancode } = <{ plancode: any }>this.auth_$.userData.getValue();
    const usersLimit = await this.getPlanUsersLimit({ plancode });
    const usersCount = await this.getUsersCount(this.auth_$.uid);

    return usersLimit > usersCount;
  }

  /**
   * Just sticks string into a large urlstring.
   *
   * @param {string} caseName
   * @param {string} up_datastorename
   * @param {string} studyuid
   * @return {*}
   * @memberof FirebaseUtilitiesService
   */
  viewerurlGenerator(caseName: string, up_datastorename: string, studyuid: string): string {
    caseName = caseName.replace(/\s/g, '');
    up_datastorename = up_datastorename.replace(/\s/g, '');
    studyuid = studyuid.replace(/\s/g, '');
    return (
      `/projects/` +
      `${environment.config.gapi.projectname}/locations/` +
      `${environment.config.gapi.location}/datasets/` +
      `${caseName}/dicomStores/` +
      `${up_datastorename}` +
      `${studyuid}`
    );
  }

  /**
   * Validate is the given email is already registered as user in firestore.
   *
   * @param {string} email
   * @return {*}  {Promise<boolean>} true if exists and false if not.
   * @memberof FirebaseUtilitiesService
   */
  async validateEmailAlreadyExists(email: string): Promise<boolean> {
    return (
      (
        await dbUsers
          .where('email', '==', email)
          .limit(1)
          .get()
          .catch(err => {
            console.log('err :', err);
            throw err;
          })
      ).docs.length === 0
    );
  }

  /**
   *
   *
   * @param {string} uid
   * @param {string} phoneNumber
   * @return {*}
   * @memberof FirebaseUtilitiesService
   */
  async validatePhoneNumberByUserID(uid: string, phoneNumber: string) {
    let emailUserDocs: string | any[];

    try {
      emailUserDocs = (
        await dbUsers
          .where('uid', '!=', uid)
          .where('phoneNumber', '==', phoneNumber)
          .limit(1)
          .get()
          .catch(err => {
            console.log('err :', err);
            throw err;
          })
      ).docs;
    } catch (error) {
      return error;
    }

    // NOTE: The phoneNumber doesn't exist.
    if (!emailUserDocs.length) {
      await this.updateUserAuthPhoneNumber(phoneNumber);
      await this.updateUserPhoneNumber(phoneNumber);
      return true;
    }

    // NOTE: The email exists.
    const emailUser = emailUserDocs[0].data();

    // NOTE: Check is the email entered is in database and its uid is the same that the current user (uid).
    if (emailUser['uid'] === uid) {
      console.log('You are overwriting with the same email address.');
      return true;
    } else {
      console.error('This email address is already taken, please use a different one.');
      return false;
    }
  }

  async validate2FACode(email: string, code: any, type: string) {
    const range = 30; // 30 minutes
    const userData = (
      await dbUsers
        .where('email', '==', email)
        .limit(1)
        .get()
        .catch(err => {
          console.log('err :', err);
          throw err;
        })
    ).docs[0].data();
    const { twoFA_timestamp, twofactorCode } = userData;

    const codeRegisteredAt = twoFA_timestamp.toDate();
    console.log('codeRegisteredAt :', codeRegisteredAt);
    const limitDate = new Date(codeRegisteredAt.getTime() + range * 60000);
    console.log('limitDate :', limitDate);
    const currentDate = firebase.firestore.Timestamp.now().toDate();
    console.log('currentDate :', currentDate);

    if (currentDate <= limitDate && currentDate > codeRegisteredAt) {
      // pass
      const dbCode = JSON.parse(twofactorCode).code;
      if (parseInt(dbCode, 10) === parseInt(code, 10)) {
        try {
          await this.setTwoFactorAuthSetup(email, type, true, true);
        } catch (err) {
          console.error(err);
          return new Error(err);
        } finally {
          console.log(`2fa set.`);
          return true;
        }
      } else {
        this.uiMessaging_$.toastMessage('The code entered is invalid, please try again', null);
        return new Error(`2fa couldn't be set. Please try again.`);
      }
    } else {
      // rejected
      this.uiMessaging_$.toastMessage('The code is not valid, please try *send me a new code*', null);
      return new Error(`Code invalid.`);
    }
  }

  getConsultantsByClientId(ownerUID: any) {
    throw new Error('Method not implemented.');
  }

  private addFileToFirestore(
    fileObject: firebase.firestore.DocumentData,
  ): Promise<firebase.firestore.DocumentReference<firebase.firestore.DocumentData>> {
    return dbFiles.add(fileObject);
  }

  private getFileId(caseName) {
    return `${new Date().getTime().toString()}_${this.getRandomString(6)}_${caseName}`;
  }

  private generateViewerUrl(studyuid, caseName, up_datastorename) {
    return studyuid ? this.viewerurlGenerator(caseName, up_datastorename, studyuid) : '';
  }

  private createFileObject(params: CreateFileObjectParams) {
    const prefix = params.type === 'dicom disk (medical images)' ? 'DICOMDIR_' : '';
    const fileId = `${prefix}${this.getFileId(params.caseName)}`;
    const fileObject = {
      belongsTo: params.caseName,
      creator: params.creator,
      fdate: params.studyuid === null ? params.entry.fdate : params.entryMeta.fdate,
      ftime: params.entry.ftime || params.entryMeta.ftime || null,
      fileDesc: params.entry.desc || '',
      fileId: fileId,
      objectID: fileId,
      fileName: params.entry.name,
      filePath: params.path,
      forDelete: false,
      ftype: params.uploadTypes[params.type] || '',
      type: (params.uploadTypes[params.type] || '').toLowerCase(),
      lastModified: params.lastModified,
      notes: [],
      parentFolderName: params.parentFolder,
      parentFolder: params.entryMeta.targetFolderId || params.parentFolderId || '',
      scanAnalysisId: params.scanAnalysisId || '',
      storename: `${params.studyuid === null ? '' : params.up_datastorename}`,
      uploadedDate: params.uploadedDate || '',
      viewerurl: this.generateViewerUrl(params.studyuid, params.caseName, params.up_datastorename),
    };

    return fileObject;
  }

  public getCurrentUserUID() {
    return this.auth_$.userData.getValue()['uid'];
  }

  createNotificationsV2(notifications: Notification[]) {
    const promises = [];
    notifications.forEach(notification => promises.push(this.createNotification(notification)));
    return Promise.all(promises).then(result => {
      console.log('>>>> Notifications created <<<<<', result);
    });
  }

  async getAssociatesAndAdmins(ownerId: any, exceptionUser: UserData): Promise<User[]> {
    return (
      await firebase
        .firestore()
        .collection('users')
        .where('owners', 'array-contains', ownerId)
        .where('disabled', '==', false)
        .get()
        .catch(err => {
          console.log('err :', err);
          throw err;
        })
    ).docs.map(doc => {
      const docData: any = { ...doc.data(), id: doc.id };
      if (exceptionUser?.email !== docData.email)
        return { id: docData.id, email: docData.email, role: docData.role, uid: docData.uid };
    });
  }

  async getOwnerIdByUserId(uid: string) {
    const querySnapshot = await this.auth_$._getUserByUID(uid);
    return querySnapshot.docs[0].data().ownerID;
  }

  /*SECTION Notification methods */
  getNotificationsByUserId(userId: string) {
    return firebase
      .firestore()
      .collection('notifications')
      .where('userId', '==', userId)
      .get()
      .catch(err => {
        console.log('err :', err);
        throw err;
      });
  }

  deleteNotification(noteId) {
    firebase.firestore().collection('notifications').doc(noteId).collection('notes').doc(noteId).delete();
  }

  private generateNotificationId(userId: string, type: string, caseId: string): string {
    return `n-${type}-${caseId}-${userId}-${new Date().getTime()}`;
  }

  createNotification(notificationData: NotificationData) {
    const docId = this.generateNotificationId(notificationData.userId, notificationData.type, notificationData.caseId);
    return firebase
      .firestore()
      .collection('notifications')
      .doc(docId)
      .set(notificationData)
      .then(() => ({ docId, ...notificationData }));
  }

  getNotificationsByFolderId(folderId: string) {
    return firebase
      .firestore()
      .collection('notifications')
      .where('folderId', '==', folderId)
      .get()
      .catch(err => {
        console.log('err :', err);
        throw err;
      });
  }

  getNotificationsByCasename(casename: string) {
    return firebase
      .firestore()
      .collection('notifications')
      .where('casename', '==', casename)
      .get()
      .catch(err => {
        console.log('err :', err);
        throw err;
      });
  }

  getNotificationsByFileId(fileId: string) {
    return firebase
      .firestore()
      .collection('notifications')
      .where('fileId', '==', fileId)
      .get()
      .catch(err => {
        console.log('err :', err);
        throw err;
      });
  }

  private getSharedUsers(sharedUsers, useremail, name) {
    if (sharedUsers && sharedUsers.length > 0) {
      const matchedByEmail = sharedUsers.filter(({ email }) => email.toLowerCase() === useremail.toLowerCase()).length;
      if (matchedByEmail) {
        this.uiMessaging_$.toastMessage('Something went wrong', 'URGENT');
        return;
      }
      return sharedUsers.concat([{ email: useremail.toLowerCase(), name: name }]);
    } else return [{ email: useremail.toLowerCase(), name: name }];
  }

  private async updateSharedUsersById(id, sharedUsers) {
    return dbFiles
      .doc(id)
      .update({ sharedUsers })
      .catch(err => err);
  }

  /**
   *
   *
   * @public
   * @param {*} fileData
   * @param {Params} params
   * @param {*} file
   * @param {string} notificationType
   * @memberof UploadHelperService
   */
  public createNotifications(
    fileData: { id: string },
    params: CreateNotificationsParams,
    file: { parentFolderId: string },
    notificationType: string,
  ) {
    const notificationData = this.getNotificationData(fileData, params, file, notificationType);
    this.createNotificationV2(notificationData, this.getCurrentUser(), params.targetUser).then(createdNotification => {
      console.log('Notifications created', createdNotification);
    });
  }

  public getNotificationsL(notificationsData: NotificationData, targetAccounts: any[]) {
    // Find identical objects in targetAccounts array and remove them.
    const uniqueTargetAccounts = targetAccounts.filter(
      (item, index, self) => index === self.findIndex(t => t.email === item.email),
    );

    const notifications = uniqueTargetAccounts.map(({ uid, role }) => ({
      userId: uid,
      userRole: role,
      type: notificationsData.type,
      fileId: notificationsData.fileId,
      fileType: notificationsData.fileType,
      folderId: notificationsData.folderId,
      noteId: notificationsData.noteId || '',
      caseId: notificationsData.caseId,
    }));

    return notifications;
  }

  private async getOwnerId(uid: string, userRole: string) {
    if (userRole === UserRoles.owner) return uid;
    return await this.getOwnerIdByUserId(uid);
  }

  async fireNewFileNotifications(targetAccounts: any[], notificationsData: NotificationData) {
    // newfile notifications should be allowed for consultants.
    return this.createNotificationsV2(this.getNotificationsL(notificationsData, targetAccounts));
  }

  async fireCreateSharedFileNotifications(targetAccounts: any[], notificationsData: NotificationData) {
    return this.createNotificationsV2(this.getNotificationsL(notificationsData, targetAccounts));
  }

  async fireModifiedNoteNotifications(targetAccounts: any[], notificationsData: NotificationData) {
    return this.createNotificationsV2(this.getNotificationsL(notificationsData, targetAccounts));
  }

  async fireNewNoteNotifications(targetAccounts: any[], notificationsData: NotificationData) {
    return this.createNotificationsV2(this.getNotificationsL(notificationsData, targetAccounts));
  }

  private async getOwner(ownerUID: string) {
    const owner = await this.getUserByUID(ownerUID);
    return { uid: ownerUID, id: owner.id, email: owner.email, role: UserRoles.owner };
  }

  private async createNewNoteNotifications(notificationData: NotificationData, currentUser) {
    const ownerUID = await this.getOwnerId(notificationData.userId, notificationData.userRole);
    let targetAccounts = await this.getAssociatesAndAdmins(ownerUID, currentUser);
    if (![UserRoles.owner].includes(currentUser.role)) {
      const ownerData = await this.getOwner(ownerUID);
      targetAccounts = [...targetAccounts, ownerData].filter(item => item !== undefined);
    }

    return this.fireNewNoteNotifications(targetAccounts, notificationData);
  }

  private async createModifiedNoteNotifications(notificationData: NotificationData, currentUser) {
    const ownerUID = await this.getOwnerId(notificationData.userId, notificationData.userRole);
    let targetAccounts = await this.getAssociatesAndAdmins(ownerUID, currentUser);
    if (UserRoles.owner !== currentUser.role) {
      const ownerData = await this.getOwner(ownerUID);
      targetAccounts = [...targetAccounts, ownerData].filter(item => item !== undefined);
    }

    return this.fireModifiedNoteNotifications(targetAccounts, notificationData);
  }

  private async createNewFileNotifications(notificationData: NotificationData, currentUser) {
    const ownerUID = await this.getOwnerId(notificationData.userId, notificationData.userRole);
    let targetAccounts = await this.getAssociatesAndAdmins(ownerUID, currentUser);
    if (![UserRoles.owner].includes(currentUser.role)) {
      const ownerData = await this.getOwner(ownerUID);
      targetAccounts = [...targetAccounts, ownerData].filter(item => item !== undefined);
    }

    return this.fireNewFileNotifications(targetAccounts, notificationData);
  }

  private async createSharedFileNotifications(notificationData: NotificationData, currentUser, targetUser?: User) {
    const ownerUID = await this.getOwnerId(notificationData.userId, notificationData.userRole);
    let targetAccounts = await this.getAssociatesAndAdmins(ownerUID, currentUser);

    if (targetUser) {
      targetAccounts = [...targetAccounts, targetUser];
    }

    if (![UserRoles.owner].includes(currentUser.role)) {
      const ownerData = await this.getOwner(ownerUID);
      targetAccounts = [...targetAccounts, ownerData].filter(item => item !== undefined);
    }

    return this.fireCreateSharedFileNotifications(targetAccounts, notificationData);
  }

  private createNotificationV2(notificationData: NotificationData, currentUser: UserData, targetUser?: User) {
    switch (notificationData.type) {
      case NotificationTypes.newfile:
        return this.createNewFileNotifications(notificationData, currentUser);
        break;
      case NotificationTypes.sharedfile:
        return this.createSharedFileNotifications(notificationData, currentUser, targetUser);
        break;
      case NotificationTypes.newnote:
        return this.createNewNoteNotifications(notificationData, currentUser);
        break;
      case NotificationTypes.modifiednote:
        return this.createModifiedNoteNotifications(notificationData, currentUser);
        break;
      default:
        break;
    }
  }

  /**
   *
   *
   * @private
   * @return {*}
   * @memberof UploadHelperService
   */
  private getCurrentUser(): any {
    const { email, role, uid } = <{ email: string; role: string; uid: string }>this.auth_$.userData.getValue();
    return { id: this.auth_$.userData.getValue()['id'], email, role, uid };
  }

  /**
   *
   *
   * @private
   * @param {*} { id }
   * @param {*} { type, uploadBlock }
   * @param {*} file
   * @param {*} notificationType
   * @return {*}  {*}
   * @memberof FirebaseUtilitiesService
   */
  private getNotificationData(
    { id }: any,
    { type, uploadBlock, noteId, targetUser }: any,
    file: any,
    notificationType: any,
  ): any {
    // this.notifications_$.fileAdded(params, file);
    const { uid, role } = <{ uid: string; role: string }>this.auth_$.userData.getValue();
    let notificationData: NotificationData = {
      userId: uid,
      userRole: role,
      type: notificationType,
      fileId: id,
      fileType: type === 1 ? 'file' : 'dicom',
      folderId: file.parentFolderId || file.entryMeta?.targetFolderId,
      caseId: uploadBlock.casename,
      noteId: noteId || null,
    };

    return notificationData;
  }

  private storeAttachment(
    filePath: string,
    attachment: Blob | Uint8Array | ArrayBuffer,
    metadata: firebase.storage.UploadMetadata,
  ) {
    console.log('====================================================');
    console.log('filePath: ', filePath);
    console.log('====================================================');

    return new Promise((resolve, reject) => {
      firebase
        .storage()
        .ref()
        .child(filePath)
        .put(attachment, metadata)
        .on(
          'state_changed',
          sp => console.log('In progress'),
          error => {
            console.log('====================================================');
            console.log('error: ', error);
            console.log('====================================================');
            reject(error);
          },
          () => {
            console.log('Finished');
            resolve(1);
          },
        );
    });
  }

  public cleanUserDataNotifications(userId, fileId, notificationTypes) {
    const currentNotifications = this.auth_$.userData.getValue()['notifications'];
    const newNotifications = [];
    currentNotifications.forEach(notification => {
      if (
        notification.userId !== userId ||
        notification.fileId !== fileId ||
        !notificationTypes.includes(notification.type)
      ) {
        newNotifications.push(notification);
      }
    });
    this.auth_$.userData.next({ ...this.auth_$.userData.getValue(), notifications: newNotifications });
  }

  /**
   * Get the owner Id of the client.
   *
   * @private
   * @param {string} clientId
   * @return {*}
   * @memberof FirebaseUtilitiesService
   */
  private async getOwnerIDByClientId(clientId: string) {
    return (
      await dbPatients
        .where('caseName', '==', clientId)
        .limit(1)
        .get()
        .catch(err => {
          console.log('err :', err);
          throw err;
        })
    ).docs[0].data().ownerID;
  }

  /**
   * List all consultants with this ownerID.
   *
   * @private
   * @param {string} ownerID
   * @return {*}
   * @memberof FirebaseUtilitiesService
   */
  private async getConsultantsByOwnerID(ownerID: string) {
    return (
      await dbUsers
        .where('role', '==', 'Consultant')
        .where('owners', 'array-contains', ownerID)
        .get()
        .catch(err => {
          console.log('err :', err);
          throw err;
        })
    ).docs;
  }

  private async getConsultantsAndClientByOwnerID(ownerID: string) {
    return (
      await dbUsers
        .where('role', 'in', ['Consultant', 'Client'])
        .where('owners', 'array-contains', ownerID)
        .get()
        .catch(err => {
          console.log('err :', err);
          throw err;
        })
    ).docs;
  }

  private fillAvailableConsultants(consultants, againstList) {
    const results = [];
    consultants
      .map(consultant => consultant.data())
      .forEach(consultant => {
        if (!consultant.disabled && !againstList.some(u => u.email === consultant.email)) {
          results.push(consultant);
        }
      });
    return this.sortUsersList(results, 'Client');
  }

  async getAvailableConsultantsAndClient(clientId: string, againstList: any[]) {
    const ownerID = await this.getOwnerIDByClientId(clientId);
    const consultants = await this.getConsultantsAndClientByOwnerID(ownerID);
    const availableLocalAllUsers = this.fillAvailableConsultants(consultants, againstList);
    const filteredByClientId = availableLocalAllUsers.filter(
      ({ clientMatter }) => !clientMatter || clientMatter === clientId,
    );
    return filteredByClientId;
  }

  makeProfilePublic(patientDocId: string, uid: string) {
    return firebase
      .firestore()
      .collection('patients')
      .doc(patientDocId)
      .update({
        restrictedProfiles: firebase.firestore.FieldValue.arrayRemove(uid),
        allowedProfiles: firebase.firestore.FieldValue.arrayUnion(uid),
      });
  }

  makeProfileRestricted(patientDocId: string, uid: string) {
    return firebase
      .firestore()
      .collection('patients')
      .doc(patientDocId)
      .update({
        restrictedProfiles: firebase.firestore.FieldValue.arrayUnion(uid),
        allowedProfiles: firebase.firestore.FieldValue.arrayRemove(uid),
      });
    // throw new Error('Method not implemented.');
  }

  private async getDocIdAndData(useremail) {
    let doc = (
      await dbUsers
        .where('email', '==', useremail)
        .get()
        .catch(err => {
          console.log('err :', err);
          throw err;
        })
    ).docs[0];
    let { uid, role } = doc.data();
    const userDocId = doc.id;
    const userUid = uid;
    const userRole = role;
    return { userDocId, userUid, userRole };
  }

  async getNotesByFileId(fileId: any) {
    return (
      await firebase
        .firestore()
        .collection('files')
        .where('fileId', '==', fileId)
        .get()
        .catch(err => {
          console.log('err :', err);
          throw err;
        })
    ).docs[0].data().notes;
  }

  deleteNotificationsByTypeUserIdAndFileId(notificationTypes: string[], uid: any, fileId: any) {
    firebase
      .firestore()
      .collection('notifications')
      .where('userId', '==', uid)
      .where('fileId', '==', fileId)
      .where('type', 'in', notificationTypes)
      .get()
      .catch(err => {
        console.log('err :', err);
        throw err;
      })
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          doc.ref.delete();
        });
      });
  }

  private filterAndSort(customFolders: any[], filter: string) {
    return (
      filter && filter !== ''
        ? customFolders.filter(folder => folder.name.toLowerCase().indexOf(filter.toLowerCase()) === 0)
        : customFolders
    ).sort(this.dynamicSort('name'));
  }

  private sortUsersList(usersList: any[], roleOnTop: string) {
    const roleOnTopUsers = usersList.filter(user => user.role === roleOnTop);
    const restOfUsers = usersList.filter(user => user.role !== roleOnTop);
    return roleOnTopUsers.concat(restOfUsers);
  }

  async createFirestoreFilesDuplicates(selected: any[], { folderId, folderName }) {
    const promisesStack = [];
    selected.forEach(async ({ id }) => {
      console.log('/files/' + id);
      promisesStack.push(
        dbFiles.doc(id).update({ parentFolders: firebase.firestore.FieldValue.arrayUnion({ folderId, folderName }) }),
      );
    });

    return Promise.all(promisesStack);
  }

  private async getUsersByRolesAndOwnerID(allowedRoles: string[], ownerID: string) {
    return (
      await dbUsers
        .where('role', 'in', allowedRoles)
        .where('owners', 'array-contains', ownerID)
        .get()
        .catch(err => {
          console.log('err :', err);
          throw err;
        })
    ).docs
      .map((g: any) => ({ ...g.data(), id: g.id }))
      .filter(({ disabled }) => disabled === false || disabled === undefined);
  }

  sendEmailNotification(messageData, action) {
    return firebase.functions().httpsCallable('email-sendEmailNotificationV3')({ messageData, action });
  }

  getAdminEmail() {
    return 'admin@nuagedx.com';
  }
}
