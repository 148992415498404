<app-window-title style="width: 100%"
                  [closable]="true"
                  [subtitle]="Labels.clientMatterLabel+': ' + data.patient.caseName"
                  [title]="'Create '+ Labels.clientMatterLabel + ' Profile user'"></app-window-title>
<p>
  A Client Upload Account will be able to access only to this Client Profile and upload documents, files and medical
  images to it.
</p>
<form *ngIf="clientForm"
      fxLayout="column"
      [formGroup]="clientForm"
      (ngSubmit)="onSubmit()">
  <div fxLayout="row"
       fxLayoutGap="15px">
    <mat-form-field appearance="fill"
                    fxFlex="50">
      <mat-label>
        First Name
      </mat-label>
      <input required
             formControlName="firstname"
             matInput>
      <mat-error *ngIf="clientForm.controls['firstname'].invalid">
        {{getErrorMessage(clientForm.controls['firstname'], 'firstname')}}
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill"
                    fxFlex="50">
      <mat-label>
        Last Name
      </mat-label>
      <input required
             formControlName="lastname"
             matInput>
      <mat-error *ngIf="clientForm.controls['lastname'].invalid">
        {{getErrorMessage(clientForm.controls['lastname'], 'lastname')}}
      </mat-error>
    </mat-form-field>
  </div>
  <div fxLayout="row"
       fxLayoutGap="15px">
    <mat-form-field appearance="fill"
                    fxFlex="50">
      <mat-label>
        Email
      </mat-label>
      <input required
             type="email"
             formControlName="email"
             matInput>
      <mat-error *ngIf="clientForm.controls['email'].invalid">
        {{getErrorMessage(clientForm.controls['email'], 'email')}}
      </mat-error>
    </mat-form-field>

    <div fxFlex="50">
      <mat-form-field style="display: block"
                      appearance="fill">
        <mat-label>
          Cell Phone
        </mat-label>
        <input placeholder="Ex. (000) 000-0000"
               required
               formControlName="phone"
               mask="(000) 000-0000"
               matInput>
        <mat-error *ngIf="clientForm.controls['phone'].invalid">
          {{getErrorMessage(clientForm.controls['phone'], 'phone')}}
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div fxFlex="50"
       fxLayout="column">
    <!-- PASSWORD FIELD -->
    <mat-form-field appearance="fill">
      <mat-label>
        Password
      </mat-label>
      <mat-pass-toggle-visibility #toggle
                                  matSuffix></mat-pass-toggle-visibility>
      <input class="password-input"
             required
             formControlName="password"
             matInput
             minlength="6"
             [type]="toggle.type">
      <mat-error *ngIf="!clientForm.controls.password.valid">
        {{getErrorMessage(clientForm.controls.password, 'password')}}
      </mat-error>
      <mat-error *ngIf="clientForm.invalid && clientForm.errors">
        The password is not strong enough.
      </mat-error>
    </mat-form-field>
    <br>
    <mat-password-strength #passwordComponent
                           [customValidator]="whitespace"
                           [password]="clientForm.controls.password.value"
                           (onStrengthChanged)="onStrengthChanged($event)">
    </mat-password-strength>
    <mat-password-strength-info [passwordComponent]="passwordComponent"></mat-password-strength-info>

    <br>
    <!-- CONFIRTM PASSWORD -->
    <mat-form-field appearance="fill">
      <mat-label>
        Confirm password
      </mat-label>
      <mat-pass-toggle-visibility #toggle_cp
                                  matSuffix></mat-pass-toggle-visibility>
      <input class="password-input"
             name="confirmpassword"
             required
             formControlName="passwordconfirm"
             matInput
             [type]="toggle_cp.type">
      <mat-error *ngIf="!clientForm.controls.passwordconfirm.valid">
        {{ getErrorMessage(clientForm.controls.passwordconfirm, 'passwordconfirm', 1) }}
      </mat-error>
    </mat-form-field>
    <mat-card class="info-box mat-small">
      Note: A password reset link will be sent to the new user.
    </mat-card>
  </div>
  <p class="mat-body"
     style="color: red">
    {{ generalError }}
  </p>

  <button color="warn"
          type="submit"
          mat-raised-button>
    CREATE USER
  </button>
</form>
