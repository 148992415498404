import { Component, OnInit } from '@angular/core';
import { DialogService } from 'src/app/dialog.service';
import { PatientsListComponent } from '../patients-list/patients-list.component';

@Component({
  selector: 'app-library',
  templateUrl: './library.component.html',
  styleUrls: ['./library.component.scss'],
})
export class LibraryComponent implements OnInit {
  selectedFiles: string[] = [];
  patients = [
    { name: 'John Doe', age: 45, lastVisit: new Date('2023-01-15') },
    { name: 'Jane Smith', age: 34, lastVisit: new Date('2023-02-20') },
    { name: 'Alice Johnson', age: 29, lastVisit: new Date('2023-03-10') },
    { name: 'Bob Brown', age: 52, lastVisit: new Date('2023-04-05') },
    { name: 'Charlie Davis', age: 40, lastVisit: new Date('2023-05-12') },
    { name: 'Diana Evans', age: 37, lastVisit: new Date('2023-06-18') },
    { name: 'Frank Green', age: 50, lastVisit: new Date('2023-07-22') },
    { name: 'Grace Harris', age: 28, lastVisit: new Date('2023-08-30') },
    { name: 'Henry Jackson', age: 33, lastVisit: new Date('2023-09-14') },
    { name: 'Ivy King', age: 41, lastVisit: new Date('2023-10-01') },
    { name: 'Jack Lee', age: 36, lastVisit: new Date('2023-11-05') },
    { name: 'Karen Martinez', age: 44, lastVisit: new Date('2023-12-12') },
    { name: 'Leo Nelson', age: 39, lastVisit: new Date('2023-01-20') },
    { name: 'Mia Owens', age: 32, lastVisit: new Date('2023-02-25') },
    { name: 'Nathan Parker', age: 47, lastVisit: new Date('2023-03-30') },
    { name: 'Olivia Quinn', age: 35, lastVisit: new Date('2023-04-15') },
    { name: 'Paul Roberts', age: 38, lastVisit: new Date('2023-05-20') },
    { name: 'Quinn Scott', age: 42, lastVisit: new Date('2023-06-25') },
    { name: 'Rachel Turner', age: 31, lastVisit: new Date('2023-07-30') },
    { name: 'Steve Wilson', age: 46, lastVisit: new Date('2023-08-15') },
  ];

  constructor(public dialog_$: DialogService) {}

  selectAll() {
    this.selectedFiles = this.uploadedFiles.map(file => file.name);
    // Check all checkboxes
    this.uploadedFiles.forEach(file => (file.selected = true));
  }

  isShareButtonDisabled(): boolean {
    return this.uploadedFiles.filter(file => file.selected).length === 0;
  }

  isAnySelected(): import('@angular/cdk/coercion').BooleanInput {
    throw new Error('Method not implemented.');
  }

  selection: any;
  displayedColumns: any;
  dataSource: { name: string; size: number; uploadedDate: Date }[] = [];
  uploadedFiles: { name: string; size: number; uploadedDate: Date; selected: boolean }[] = [];

  ngOnInit(): void {
    this.dataSource = [
      { name: 'File1.txt', size: 1024, uploadedDate: new Date() },
      { name: 'File2.jpg', size: 2048, uploadedDate: new Date() },
      { name: 'File3.pdf', size: 3072, uploadedDate: new Date() },
      { name: 'File4.docx', size: 4096, uploadedDate: new Date() },
      { name: 'File5.png', size: 5120, uploadedDate: new Date() },
    ];

    this.uploadedFiles = Array.from({ length: 50 }, (_, i) => ({
      name: `file${i + 1}.txt`,
      size: Math.floor(Math.random() * 10000) + 1000,
      uploadedDate: new Date(2023, i % 12, (i % 28) + 1),
    })).map(file => ({ ...file, selected: false }));
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.uploadedFiles.forEach(file => this.selection.select(file));
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.uploadedFiles.length;
    return numSelected === numRows;
  }

  isSomeSelected() {
    const numSelected = this.selection.selected.length;
    return numSelected > 0 && numSelected < this.uploadedFiles.length;
  }

  toggleSelection(file: any, event) {
    // If the checkbox is checked, add the file to the selectedFiles array
    if (event.checked) {
      this.selectedFiles.push(file.name);
      file.selected = true;
    } else {
      // If the checkbox is unchecked, remove the file from the selectedFiles array
      const index = this.selectedFiles.indexOf(file.name);
      if (index > -1) {
        this.selectedFiles.splice(index, 1);
      }
      file.selected = false;
    }

    console.log(this.selectedFiles);
  }

  deleteFile(file: any): void {
    const index = this.uploadedFiles.indexOf(file);
    if (index > -1) {
      this.uploadedFiles.splice(index, 1);
    }
  }

  copyFileTo() {
    // Open a MatDialog with the PatientList component inside
    this.dialog_$.open(PatientsListComponent, {
      width: '800px',
      data: {
        patients: this.patients,
        selectedFiles: this.selectedFiles,
      },
    });
  }

  isIndeterminate(): import('@angular/cdk/coercion').BooleanInput {
    throw new Error('Method not implemented.');
  }

  viewPatientDetails(_t44: any) {
    throw new Error('Method not implemented.');
  }
}
